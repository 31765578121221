import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  buildThunkPrefix,
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetSpaceTypes } from 'src/api/Space'
import { createSelector } from 'reselect'

const NAMESPACE = 'SpaceTypes'
const actionClearData = actionClearDataHandler(NAMESPACE)

const selectModule = state => state.modules.SpaceTypes
const selectAllSpaceTypes = createSelector(selectModule, module => module.data)

const fetchSpaceTypes = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaceTypes'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceTypesInstance = new GetSpaceTypes(store, {})

    const response = await handleCreateAsyncThunkResult(
      getSpaceTypesInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)
const spaceTypesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceTypes.pending, pendingDataHandler)
    builder.addCase(fetchSpaceTypes.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceTypes.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: spaceTypesSlice.reducer }

export { selectAllSpaceTypes, actionClearData, fetchSpaceTypes }
