import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createSpacePlan, updateSpacePlanById, approveSpacePlanById } from '../../store'
import Form from './Form'
import mapStateToProps from './mapStateToProps'

const FormContainer = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const stateProps = useSelector(state => mapStateToProps(state, props))

  const updateSpacePlanInfoById = ({ spacePlan, id }) => {
    const action =
      spacePlan.status === 'approved'
        ? approveSpacePlanById({ id, spacePlan })
        : updateSpacePlanById({ id, spacePlan })
    return action
  }

  const returnAction = () => navigate('/space-plans')

  const submit = async (spacePlan, id) => {
    const action = id ? updateSpacePlanInfoById({ spacePlan, id }) : createSpacePlan(spacePlan)

    dispatch(action)
    returnAction()
  }

  const cancel = () => returnAction()

  return <Form {...props} {...stateProps} submit={submit} cancel={cancel} />
}

export default FormContainer
