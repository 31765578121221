import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetCycles } from 'src/api/Cycle'
import { createSelector } from 'reselect'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'Cycles'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const selectModule = state => state.modules.Cycles
const selectCycles = createSelector(selectModule, module => module.data)
const selectCycleStatus = createSelector(selectModule, module => module?.isFetching)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchCycles = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchCycles'),
  async (businessUnit, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getCyclesInstance = new GetCycles(store, {
      queryParams: { businessUnit },
    })

    const response = await handleCreateAsyncThunkResult(
      getCyclesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const cyclesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCycles.pending, pendingDataHandler)
    builder.addCase(fetchCycles.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCycles.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: cyclesSlice.reducer }

export { fetchCycles, selectCycles, actionClearData, selectCycleStatus }
