import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const PageNotFound = ({ title, message, linkUrl, linkTitle }) => (
  <Section>
    <Heading2>
      <span data-control="404-title">{title}</span>
    </Heading2>
    <p>{message}</p>
    <Link to={linkUrl}>{linkTitle}</Link>
  </Section>
)

PageNotFound.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  linkUrl: PropTypes.string,
  linkTitle: PropTypes.string,
}

PageNotFound.defaultProps = {
  title: 'Page Not Found',
  message: 'The page you are looking for cannot be found.',
  linkUrl: '/',
  linkTitle: 'Back to dashboard',
}

export default PageNotFound
