import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SearchField, TextInputField, SelectField } from '@jsluna/form'
import { Search } from '@jsluna/icons'
import { Button } from '@jsluna/button'
import { Link } from 'react-router-dom'

import {
  Card,
  FilledButton,
  Heading3,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@jsluna/react'

import { actionUpdateSpaceAssignmentById } from 'src/modules/SpaceAssignment/store/reducer/spacePlanAssignment'
import { actionUpdateSpaceInstance } from 'src/modules/SpaceAssignment/store/reducer/spaceInstances'
import { createNotification } from 'src/store/notifications/actions'
import { selectSpaceAssignmentWithSspsaComments } from './store'

const clusterTitleOption = ({
  spaceInstanceId,
  groupName,
  posComment,
  shelfHeights,
  comment,
  suggestedLocation,
  shipperDescription,
}) => ({
  posComment,
  shelfHeights,
  comment,
  suggestedLocation,
  shipperDescription,
  label: groupName,
  value: spaceInstanceId,
})

class SpaceComments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      spaceAssignmentsCount: 0,
      spaceAssignmentRows: [],
      filteredSpaceAssignmentRows: [],
    }

    this.handleSearchChanges = this.handleSearchChanges.bind(this)
    this.handleStoreTitleCommentChange = this.handleStoreTitleCommentChange.bind(this)
    this.handleClusterChange = this.handleClusterChange.bind(this)
    this.handleOnPosCommentChange = this.handleOnPosCommentChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)

    const { spaceAssignments } = this.props

    const spaceAssignmentRows = []
    spaceAssignments.forEach(rowData => {
      const spaceAssignmentRow = { ...rowData }
      spaceAssignmentRow.spaceInstances = rowData.spaceInstances.map(clusterTitleOption)
      spaceAssignmentRow.enablePosCommentTextBox = rowData.spaceInstances.length === 1

      const autoSpaceInstance = rowData.spaceInstances.find(x => x.isAutoSpaceInstance === true)
      spaceAssignmentRow.spaceInstanceId = autoSpaceInstance.spaceInstanceId
      if (rowData.spaceInstances.length === 1) {
        spaceAssignmentRow.spaceInstancePosComment = autoSpaceInstance.posComment
      }

      spaceAssignmentRow.spaceAssignmentModified = false
      spaceAssignmentRow.spaceInstanceModified = false
      spaceAssignmentRow.saveButtonEnabled = false

      spaceAssignmentRows.push(spaceAssignmentRow)
    })

    this.state.spaceAssignmentRows = spaceAssignmentRows
    this.state.filteredSpaceAssignmentRows = spaceAssignmentRows
    this.state.spaceAssignmentsCount = spaceAssignmentRows.length
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleStoreTitleCommentChange(spaceAssignmentId, value) {
    const { spaceAssignmentRows } = this.state
    const spaceAssignmentRow = spaceAssignmentRows.find(
      x => x.spaceAssignmentId === spaceAssignmentId
    )
    spaceAssignmentRow.storeTitleComment = value
    spaceAssignmentRow.saveButtonEnabled = true
    spaceAssignmentRow.spaceAssignmentModified = true

    this.setState({ spaceAssignmentRows })
  }

  handleOnPosCommentChange(spaceAssignmentId, value) {
    const { spaceAssignmentRows } = this.state
    const spaceAssignmentRow = spaceAssignmentRows.find(
      x => x.spaceAssignmentId === spaceAssignmentId
    )

    const index = spaceAssignmentRow.spaceInstances.findIndex(
      x => x.value === spaceAssignmentRow.spaceInstanceId
    )
    spaceAssignmentRow.spaceInstances[index].posComment = value

    spaceAssignmentRow.spaceInstancePosComment = value
    spaceAssignmentRow.spaceInstanceModified = true
    spaceAssignmentRow.saveButtonEnabled = true
    this.setState({ spaceAssignmentRows })
  }

  handleClusterChange(spaceAssignmentId, spaceInstanceId) {
    const { spaceAssignmentRows } = this.state
    const spaceAssignmentRow = spaceAssignmentRows.find(
      x => x.spaceAssignmentId === spaceAssignmentId
    )
    spaceAssignmentRow.enablePosCommentTextBox = spaceInstanceId !== ''
    spaceAssignmentRow.spaceInstancePosComment = ''
    if (spaceInstanceId !== '') {
      spaceAssignmentRow.spaceInstanceId = spaceInstanceId
      const index = spaceAssignmentRow.spaceInstances.findIndex(
        x => x.value === spaceAssignmentRow.spaceInstanceId
      )
      spaceAssignmentRow.spaceInstancePosComment =
        spaceAssignmentRow.spaceInstances[index].posComment
    }
    this.setState({ spaceAssignmentRows })
  }

  handleOnSubmit(spaceAssignmentId) {
    const { submitSpaceAssignment, submitSpaceInstance } = this.props

    const { spaceAssignmentRows } = this.state
    const selectedSpaceAssignment = spaceAssignmentRows.find(
      x => x.spaceAssignmentId === spaceAssignmentId
    )
    if (selectedSpaceAssignment.spaceAssignmentModified) {
      submitSpaceAssignment({ ...selectedSpaceAssignment })
    }

    if (selectedSpaceAssignment.spaceInstanceModified) {
      const selectedSpaceInstance = selectedSpaceAssignment.spaceInstances.find(
        x => x.value === selectedSpaceAssignment.spaceInstanceId
      )
      submitSpaceInstance({ ...selectedSpaceInstance })
    }

    selectedSpaceAssignment.spaceInstanceModified = false
    selectedSpaceAssignment.spaceAssignmentModified = false
    selectedSpaceAssignment.saveButtonEnabled = false
    this.setState({ spaceAssignmentRows })
  }

  handleSearchChanges(searchPattern) {
    const { spaceAssignmentRows } = this.state

    if (searchPattern === undefined || searchPattern === ' ') {
      this.setState({
        filteredSpaceAssignmentRows: spaceAssignmentRows,
        spaceAssignmentsCount: spaceAssignmentRows.length,
      })
    } else {
      const filteredData = spaceAssignmentRows.filter(x =>
        x.spaceTitle.toUpperCase().includes(searchPattern.toUpperCase())
      )
      this.setState({
        filteredSpaceAssignmentRows: filteredData,
        spaceAssignmentsCount: filteredData.length,
      })
    }
  }

  render() {
    const { spaceAssignmentsCount, filteredSpaceAssignmentRows } = this.state
    const getTitle = spaceInstance => spaceInstance[0].label

    return (
      <Card>
        <Heading3 data-control="spaces-title" data-testid="aaaa">
          Spaces ({spaceAssignmentsCount} Records Found)
        </Heading3>
        <SearchField
          name="search-field-1"
          data-control="search-field"
          label="Search Spaces"
          hideLabel
          placeholder="Search Spaces"
          onChange={event => this.handleSearchChanges(event.target.value)}
          hasAction
          action={
            <Button>
              <span className="ln-u-visually-hidden">Search products</span>
              <Search />
            </Button>
          }
        />
        <div data-control="title-Comments-Table">
          <TableContainer responsive>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell key="spaceName">Space Name</TableHeaderCell>
                <TableHeaderCell key="titleComment">Store Title Comments</TableHeaderCell>
                <TableHeaderCell key="cluster">Cluster</TableHeaderCell>
                <TableHeaderCell key="posComment">POS Comments</TableHeaderCell>
                <TableHeaderCell key="actions">Actions</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody className="auto">
              {filteredSpaceAssignmentRows.map(spaceAssignmentRow => {
                const {
                  spaceAssignmentId,
                  spaceTitle,
                  storeTitleComment,
                  spaceInstances,
                  saveButtonEnabled,
                  spaceInstancePosComment,
                  enablePosCommentTextBox,
                } = spaceAssignmentRow
                return (
                  <TableRow key={spaceAssignmentId}>
                    <TableCell>
                      <Link to={`/space-assignments/${spaceAssignmentId}`}>{spaceTitle}</Link>
                    </TableCell>
                    <TableCell>
                      <TextInputField
                        style={{ width: 400 }}
                        name="text-input-field-1"
                        defaultValue={storeTitleComment}
                        onChange={event =>
                          this.handleStoreTitleCommentChange(spaceAssignmentId, event.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {spaceInstances.length !== 1 && (
                        <SelectField
                          style={{ marginTop: 10, marginLeft: -25 }}
                          name="select-field-1"
                          options={spaceInstances}
                          onChange={event => {
                            this.handleClusterChange(spaceAssignmentId, event.target.value)
                          }}
                        />
                      )}
                      {spaceInstances.length === 1 && <span>{getTitle(spaceInstances)}</span>}
                    </TableCell>
                    <TableCell>
                      <TextInputField
                        style={{ width: 400 }}
                        name="text-input-field-1"
                        disabled={!enablePosCommentTextBox}
                        defaultValue={spaceInstancePosComment}
                        value={spaceInstancePosComment}
                        onChange={event =>
                          this.handleOnPosCommentChange(spaceAssignmentId, event.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {!saveButtonEnabled && (
                        <FilledButton
                          style={{ width: 150, height: 25, marginBottom: 10 }}
                          type="submit"
                          disabled="true"
                          onClick={() => this.handleOnSubmit(spaceAssignmentId)}
                        >
                          Save
                        </FilledButton>
                      )}
                      {saveButtonEnabled && (
                        <FilledButton
                          style={{ width: 150, height: 25, marginBottom: 10 }}
                          type="submit"
                          onClick={() => this.handleOnSubmit(spaceAssignmentId)}
                        >
                          Save
                        </FilledButton>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </TableContainer>
        </div>
      </Card>
    )
  }
}

SpaceComments.propTypes = {
  spaceAssignments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  submitSpaceAssignment: PropTypes.func.isRequired,
  submitSpaceInstance: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  spaceAssignments: selectSpaceAssignmentWithSspsaComments(state),
})

const mapDispatchToProps = dispatch => ({
  submitSpaceAssignment: async selectedSpaceAssignment => {
    await dispatch(
      actionUpdateSpaceAssignmentById({
        id: selectedSpaceAssignment.spaceAssignmentId,
        assignment: selectedSpaceAssignment,
      })
    )
    dispatch(createNotification('Space assignment saved.'))
  },
  submitSpaceInstance: async selectedSpaceInstance => {
    await dispatch(
      actionUpdateSpaceInstance({
        id: selectedSpaceInstance.value,
        spaceInstance: selectedSpaceInstance,
      })
    )
    dispatch(createNotification('Space instance saved.'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SpaceComments)
