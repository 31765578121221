import { createSelector } from 'reselect'

import sortArray from 'src/utils/sortArray'

import { buildReducer, bindSelector } from 'src/store/modules'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import GetStores from 'src/api/StoreSearch'

/* CONSTANTS */

const NAMESPACE = 'StoreSearch'
const STORESLIST = 'StoresList'
const UPDATE_SEARCH = 'store-search-update-search'

/* SELECTORS */

const selectUi = bindSelector(NAMESPACE)(state => state.ui)

const selectModule = state => state.modules.StoreSearch
const selectStores = createSelector(selectModule, module => module.StoresList.data || [])

const selectFilteredStores = createSelector(selectUi, selectStores, (ui, stores) => {
  const filteredStores = stores.filter(store => {
    if (ui.query === '') {
      return true
    }

    if (store.storeNumber === ui.query) {
      return true
    }

    if (store.name.toLowerCase().indexOf(ui.query.toLowerCase()) !== -1) {
      return true
    }

    return false
  })
  return sortArray(filteredStores, 'name')
})

/* ACTIONS */

const actionClearData = actionClearDataHandler(NAMESPACE)

const updateSearch = payload => ({
  type: UPDATE_SEARCH,
  payload,
})

const actionGetStores = query => async dispatch => {
  dispatch(updateSearch({ query }))
}

/* REDUCERS */

const uiInitialState = {
  query: '',
}

const uiReducer = (state = uiInitialState, { type, payload } = {}) => {
  if (type === UPDATE_SEARCH) {
    const { query } = payload
    return { query: query && query.trim() }
  }
  return state
}

const fetchStores = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchStores'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getStoresInstance = new GetStores(store)

    const response = await handleCreateAsyncThunkResult(
      getStoresInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const storesSlice = createSlice({
  name: STORESLIST,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchStores.pending, pendingDataHandler)
    builder.addCase(fetchStores.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchStores.rejected, rejectedDataHandler)
  },
})

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [storesSlice.name]: storesSlice.reducer,
})

export {
  selectUi,
  selectStores,
  selectFilteredStores,
  actionClearData,
  actionGetStores,
  fetchStores,
}
