import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  UpdateChangeControl,
  PostChangeControl,
  DeleteChangeControlById,
} from 'src/api/ChangeControl'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'ChangeControl'
const STORE_PATH = `modules/SpaceAssignment/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const createChangeControl = createAsyncThunk(
  buildThunkPrefix('createChangeControl'),
  async (changeControl, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postChangeControlInstance = new PostChangeControl(store, {
      params: {
        ...changeControl,
      },
    })

    const response = await handleCreateAsyncThunkResult(
      postChangeControlInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const deleteChangeControlById = createAsyncThunk(
  buildThunkPrefix('deleteChangeControlById'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const deleteChangeControlByIdInstance = new DeleteChangeControlById(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      deleteChangeControlByIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const updateChangeControl = createAsyncThunk(
  buildThunkPrefix('updateChangeControl'),
  async ({ id, payload }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const newPayload = {
      id,
      ...payload,
    }
    const updateChangeControlInstance = new UpdateChangeControl(store, {
      params: { id, payload: newPayload },
    })

    const response = await handleCreateAsyncThunkResult(
      updateChangeControlInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const updateChangeControlSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateChangeControl.pending, pendingDataHandler)
    builder.addCase(updateChangeControl.fulfilled, fullFilledDataHandler)
    builder.addCase(updateChangeControl.rejected, rejectedDataHandler)
  },
})

export { actionClearData, updateChangeControl, createChangeControl, deleteChangeControlById }

export default updateChangeControlSlice
