import { combineReducers, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { PostGroup, PatchGroup, GetGroupById } from 'src/api/Group'
import { GetSpacesBySpacePlan } from 'src/api/Space'
import { GetSpacePlanTitles } from 'src/api/SpacePlan'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'GroupDetail'
const STORE_PATH = `modules/${NAMESPACE}`
const GROUP_NAME = 'Groups'
const SPACES_NAME = 'Spaces'
const SPACE_PLAN_NAME = 'SpacePlans'
const buildThunkPrefix = (slice, thunkName) => `${STORE_PATH}/${slice}/${thunkName}`

const selectModules = state => state.modules.GroupDetail
const selectSpaces = createSelector(selectModules, module => module.Spaces.data)
const selectSpacePlans = createSelector(selectModules, module => module.SpacePlans.data)
const selectGroup = createSelector(selectModules, module => module?.Groups?.data)
const selectGroupSpaces = createSelector(
  selectGroup,
  group => group?.spaces && group?.spaces.map(space => space.id)
)
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const actionCreateGroup = createAsyncThunk(
  buildThunkPrefix(GROUP_NAME, 'createGroup'),
  async (group, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postGroupInstance = new PostGroup(store, {
      params: { group },
    })

    const response = await handleCreateAsyncThunkResult(
      postGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const fetchSpaces = createAsyncThunk(
  buildThunkPrefix(SPACES_NAME, 'fetchSpaces'),
  async ({ spacePlanId, groupId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaces = new GetSpacesBySpacePlan(store, {
      params: { spacePlanId, groupId },
    })

    const response = await handleCreateAsyncThunkResult(getSpaces, dispatch, rejectWithValue)
    return response
  }
)
const spacesSlice = createSlice({
  name: SPACES_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaces.pending, pendingDataHandler)
    builder.addCase(fetchSpaces.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaces.rejected, rejectedDataHandler)
  },
})
const fetchSpacePlans = createAsyncThunk(
  buildThunkPrefix(SPACE_PLAN_NAME, 'fetchSpacePlans'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpacePlans = new GetSpacePlanTitles(store)

    const response = await handleCreateAsyncThunkResult(getSpacePlans, dispatch, rejectWithValue)
    return response
  }
)
const spacePlansSlice = createSlice({
  name: SPACE_PLAN_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpacePlans.pending, pendingDataHandler)
    builder.addCase(fetchSpacePlans.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpacePlans.rejected, rejectedDataHandler)
  },
})

const fetchGroupInfo = createAsyncThunk(
  buildThunkPrefix(GROUP_NAME, 'fetchGroupInfo'),
  async (groupId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchGroupInfoInstance = new GetGroupById(store, {
      params: { id: groupId },
    })

    const response = await handleCreateAsyncThunkResult(
      fetchGroupInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const groupInfoSlice = createSlice({
  name: GROUP_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGroupInfo.pending, pendingDataHandler)
    builder.addCase(fetchGroupInfo.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchGroupInfo.rejected, rejectedDataHandler)
  },
})
const actionUpdateGroup = createAsyncThunk(
  buildThunkPrefix(GROUP_NAME, 'updateGroup'),
  async (group, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchGroupInstance = new PatchGroup(store, {
      params: { groupId: group.id, group },
    })
    const response = await handleCreateAsyncThunkResult(
      patchGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
export {
  actionCreateGroup,
  actionUpdateGroup,
  actionClearData,
  fetchGroupInfo,
  fetchSpaces,
  fetchSpacePlans,
}
export { selectSpaces, selectSpacePlans, selectGroup, selectGroupSpaces }

export default {
  [NAMESPACE]: combineReducers({
    [spacesSlice.name]: spacesSlice.reducer,
    [spacePlansSlice.name]: spacePlansSlice.reducer,
    [groupInfoSlice.name]: groupInfoSlice.reducer,
  }),
}
