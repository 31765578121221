import loader from 'src/hoc/loader'
import { fetchCategories } from 'src/store/modules/asyncThunks/categories'
import { fetchSpaceAssignments } from './store/reducer/spaceAssignmentReducer'
import { actionClearData } from './store'
import Spaces from './Spaces'

const getData = async ({ params: { id: cycleId } }, dispatch) => {
  await Promise.all([dispatch(fetchSpaceAssignments(cycleId)), dispatch(fetchCategories())])
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Spaces)
