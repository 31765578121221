import { SHELF_ARTIFACTS_TYPE } from 'src/constants/types'

import { combineAndSortShelfItems } from 'src/utils/shelfItems'
import getPrecedingShelfProduct from 'src/utils/getPrecedingShelfProduct'
import trimTrailing from 'src/utils/trimTrailing'
import { isNumber } from 'src/utils/validation'
import { actionUpdateMoveShelfProduct } from 'src/modules/SpaceAssignment/store/reducer/moveShelfProduct'

import { selectSelfCategories } from 'src/store/data/selectors'

import { fetchCategories } from 'src/store/modules/asyncThunks/categories'
import { YOUR_CATEGORIES } from 'src/constants/categories'
import { fetchDeptBuyers, fetchSpaceAssignments, selectFilters, selectPagination } from '.'
import { actionUpdateShelfArtifact } from '../../SpaceAssignment/store/reducer/shelfArtifact'

const MASTER_PSA_RESET_FILTER = 'master-psa-reset-filter'
const MASTER_PSA_SET_FILTER = 'master-psa-set-filter'

const actionGetData = ({ cycleId, number = 1, filter, location }) => async (dispatch, getState) => {
  const state = getState()
  const { size } = selectPagination(getState())
  const isFull = location && location.query?.full !== undefined
  const pageNumber =
    location && location?.hash !== '' ? parseInt(location.hash.substring(1), 10) : number
  const page = !isFull && { number: isNumber(pageNumber) ? pageNumber : 1, size }

  const { category: filterCategory, space: filterSpace, buyers: filterBuyers } = selectFilters(
    state
  )
  const options = {
    id: cycleId,
    categoryIds: '',
    buyers: '',
    space: '',
    page,
  }

  if (filterCategory === YOUR_CATEGORIES) {
    const userCategories = selectSelfCategories(state).map(category => category.id)
    options.categoryIds = userCategories.join()
  } else if (filterCategory) {
    options.categoryIds = filterCategory
  }

  if (filterSpace) {
    options.space = trimTrailing(encodeURIComponent(filterSpace), ' ')
  }

  if (filterBuyers.length > 0) {
    const buyers = filterBuyers.map(buyer => buyer.label)
    options.buyers = encodeURIComponent(buyers.join())
  }

  const actions = [fetchSpaceAssignments(options)]
  if (filter === 'initalApiCall') {
    actions.push(fetchCategories(), fetchDeptBuyers())
  }
  await Promise.all(actions.map(a => dispatch(a)))
}

const isShelfArtifact = ({ type }) => type === SHELF_ARTIFACTS_TYPE

export const actionMoveShelfArtifact = (
  { id },
  toPosition,
  { cycle, number }
) => async dispatch => {
  const artifact = {
    position: toPosition,
  }

  await dispatch(actionUpdateShelfArtifact({ id, artifact }))

  dispatch(actionGetData({ cycleId: cycle.id, number }))
}

const actionMoveProduct = (id, shelf, fromPosition, toPosition, { cycle }) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const pageNumber = selectPagination(state)
  const { products, artifacts = [] } = shelf
  const sortSelfArtifacts = [...artifacts].sort((a, b) => a.position - b.position)
  const sortSelfProducts = [...products].sort((a, b) => a.position - b.position)
  const shelfItems = combineAndSortShelfItems(sortSelfProducts, sortSelfArtifacts)
  const affectedShelfItem = shelfItems[toPosition]
  const shouldMoveShelfArtifact = isShelfArtifact(affectedShelfItem)

  if (shouldMoveShelfArtifact) {
    const { position } = affectedShelfItem
    const newArtifactPosition = fromPosition >= toPosition ? position + 1 : position - 1

    await dispatch(
      actionMoveShelfArtifact(affectedShelfItem, newArtifactPosition, shelf, {
        cycle,
        number: pageNumber?.number,
      })
    )
  } else {
    const precedingShelfProduct = getPrecedingShelfProduct(shelfItems, fromPosition, toPosition)
    const shelfProduct = {
      precedingShelfProductId: precedingShelfProduct?.id,
    }

    await dispatch(actionUpdateMoveShelfProduct({ id, shelfProduct }))
    dispatch(actionGetData({ cycleId: cycle.id, number: pageNumber?.number }))
  }
}

const actionSetFilter = (filterType, value) => dispatch =>
  dispatch({ type: MASTER_PSA_SET_FILTER, payload: { filterType, value } })

const actionResetFilter = () => dispatch => dispatch({ type: MASTER_PSA_RESET_FILTER })

export {
  MASTER_PSA_RESET_FILTER,
  MASTER_PSA_SET_FILTER,
  actionGetData,
  actionMoveProduct,
  actionResetFilter,
  actionSetFilter,
}
