import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading3 } from '@jsluna/react'

import Section from 'src/components/Section'

import Form from './Form'

const Details = ({ params: { id }, authData: { user } }) => (
  <Section>
    <Heading3 className="ln-u-visually-hidden">Details</Heading3>
    <Card>
      <Form id={id} user={user} />
    </Card>
  </Section>
)

Details.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  authData: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export default Details
