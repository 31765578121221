import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import redirectAction from 'src/store/data/actions/redirect'

const Homepage = ({ redirect }) => {
  redirect('/')

  return null
}
Homepage.propTypes = {
  redirect: PropTypes.func.isRequired,
}

export default connect(null, { redirect: redirectAction })(Homepage)
