import { read, utils } from 'xlsx'
import { v4 as uuid } from 'uuid'

import { columns } from './columns'

const parseWorkbook = data => {
  const workbook = read(data, { type: 'array' })
  const sheetName = 'nominations_download'
  const sheet = workbook.Sheets[sheetName]

  if (!sheet) {
    throw Error('Worksheet not found')
  }

  const rows = utils.sheet_to_json(sheet, {
    header: columns.map(c => c.key),
    raw: false,
  })

  return rows.slice(1).map((row, index) => ({
    ...row,
    rowOrder: index,
    uuid: uuid(),
    validity: 'Pending',
  }))
}

export default parseWorkbook
