export const LOADING = 'LOADING'

export const SET_TOKEN = 'SET_TOKEN'
export const ACCESSING_TOKEN_STATUS = 'ACCESSING_TOKEN_IN_PROGRESS'
export const IN_PROGRESS = 'IN_PROGRESS'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_FAILURE = 'API_FAILURE'
