import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import login, { loginStoreColleague } from 'src/store/api/actions/login'
import createLoginHandler from 'src/utils/loginHandler'

// Pages
import changeControlsRoute from 'src/pages/change-controls'
import cyclesRoute from 'src/pages/cycles'
import importsRoute from 'src/pages/imports'
import nominationsRoute from 'src/pages/nominations'
import messagesAdmin from 'src/pages/messages-admin'
import profileRoute from 'src/pages/profile'
import exportsRoute from 'src/pages/exports'
import rangeAndSpace from 'src/pages/range-and-space'
import spaceAssignmentsRoute from 'src/pages/space-assignments'
import spacePlansRoute from 'src/pages/space-plans'
import spacesRoute from 'src/pages/spaces'
import sspsaRoute from 'src/pages/sspsa'
import usersRoute from 'src/pages/users'
import groupsRoute from 'src/pages/groups'
import categoryRoute from 'src/pages/categories'
import productRoute from 'src/pages/product'

// Modules
import App from 'src/modules/App'
import StoreColleagueApp from 'src/modules/App/StoreColleague'
import Maintenance from 'src/modules/App/Maintenance'
import Homepage from 'src/components/Homepage'
import NoAccess from 'src/components/Errors/NoAccess'
import NotFound from 'src/components/Errors/NotFound'
import Unassigned, { Submitted } from 'src/modules/Unassigned'
import Unauthorised from 'src/components/Errors/Unauthorised'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import LoggedInRoute from './components/Auth/LoggedInRoute'
import LoggedInStoreColleagueRoute from './components/Auth/LoggedInStoreColleagueRoute'
import InitialRoute from './components/Auth/InitialRoute'

function createRoutes(store) {
  const { instance, accounts, inProgress } = useMsal()

  const [isStoreColleague, setIsStoreColleague] = useState(false)

  const loginEnter = createLoginHandler(
    args => store.dispatch(login(args)),
    false,
    instance,
    accounts,
    inProgress,
    store.dispatch
  )

  const loginStoreColleagueEnter = createLoginHandler(
    args => store.dispatch(loginStoreColleague(args)),
    true,
    instance,
    accounts,
    inProgress,
    store.dispatch
  )

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isStoreColleague) {
        loginStoreColleagueEnter({ location: window.location })
      } else {
        loginEnter({ location: window.location })
      }
    }
  }, [isStoreColleague, inProgress])

  if (window?.PROMCOMM?.MAINTENANCE === 'true') {
    return (
      <Routes>
        <Route name="Maintenance" path="*" element={<Maintenance />} />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route element={<StoreColleagueApp />}>
        <Route
          name="LoginStoreColleague"
          path="/login-store-colleague"
          element={<InitialRoute setIsStoreColleague={setIsStoreColleague} isStoreColleague />}
        />
        <Route element={<LoggedInStoreColleagueRoute />}>{rangeAndSpace}</Route>
      </Route>
      <Route element={<App />}>
        <Route
          name="Login"
          path="/login"
          element={
            <InitialRoute setIsStoreColleague={setIsStoreColleague} isStoreColleague={false} />
          }
        />
        <Route element={<LoggedInRoute />}>
          <Route index element={<Homepage />} />
          {spacePlansRoute}
          {cyclesRoute}
          {messagesAdmin}
          {nominationsRoute}
          {spacesRoute}
          {usersRoute}
          {importsRoute}
          {profileRoute}
          {spaceAssignmentsRoute}
          {sspsaRoute}
          {exportsRoute}
          {changeControlsRoute}
          {groupsRoute}
          {categoryRoute}
          {productRoute}
          <Route path="/no-access" element={<NoAccess />} />
          <Route path="/unauthorised" element={<Unauthorised />} />
          <Route path="/unassigned" element={<Unassigned />} />
          <Route path="/unassigned/submitted" element={<Submitted />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route name="Not Found" path="*" element={NotFound} />
    </Routes>
  )
}

export default createRoutes
