import React from 'react'
import { connect } from 'react-redux'

import { FROZEN_LAYOUT_TYPE } from 'src/constants/layoutTypes'

import { createError } from 'src/store/notifications/actions'

import { useNavigate } from 'react-router-dom'
import { selectSpaceTypeLayouts, selectSpaceTypeInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const spaceTypeInfo = id ? selectSpaceTypeInfo(state) : {}
  const allSpaceTypeLayouts = selectSpaceTypeLayouts(state) || []
  const allLayouts = allSpaceTypeLayouts.filter(l => l.name !== FROZEN_LAYOUT_TYPE)

  const {
    shelvesDefault = 0,
    shelvesMax = 0,
    shelvesMin = 0,
    title = '',
    spaceTypeLayouts = [],
  } = spaceTypeInfo

  return {
    ...spaceTypeInfo,
    id,
    layouts: spaceTypeLayouts.map(
      ({
        defaultCapacity = 0,
        minCapacity = 0,
        maxCapacity = 0,
        layoutType,
        id: spaceTypeLayoutId,
      }) => ({
        id: spaceTypeLayoutId,
        layoutType,
        defaultCapacity: String(defaultCapacity),
        minCapacity: String(minCapacity),
        maxCapacity: String(maxCapacity),
      })
    ),
    allLayouts,
    originalTitle: title,
    shelvesDefault: String(shelvesDefault),
    shelvesMax: String(shelvesMax),
    shelvesMin: String(shelvesMin),
    title,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/spaces/types')
  }

  const mapDispatchToProps = dispatch => ({
    afterSubmit: () => returnAction(),
    cancel: () => returnAction(),
    submit: action => dispatch(action),
    showError: message => dispatch(createError(message)),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
