import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { selectSelf, selectToken, selectTokenAccessStatus } from 'src/store/data/selectors'
import LoadingCard from 'src/components/LoadingCard'
import { FAILURE } from 'src/constants/api'

const LoggedInRoute = () => {
  const [isLoading, setIsLoading] = useState(true)

  const token = useSelector(selectToken)
  const tokenAccessStatus = useSelector(selectTokenAccessStatus)
  const user = useSelector(selectSelf)

  useEffect(() => {
    if (tokenAccessStatus === FAILURE || (token && user)) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [tokenAccessStatus, token, user])
  if (isLoading) {
    return <LoadingCard />
  }
  if (!token) {
    return <Navigate to="/login" />
  }

  return <Outlet />
}

export default LoggedInRoute
