import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import RangeAndSpace from 'src/modules/RangeAndSpace'
import StoreSearch from 'src/modules/RangeAndSpace/StoreSearch'
import AuthorizedStoreColleagueComponent from 'src/components/Auth/AuthorizedStoreColleagueComponent'

const route = (
  <>
    <Route path="/range-and-space">
      <Route index element={<AuthorizedStoreColleagueComponent component={StoreSearch} />} />
      <Route
        path=":storeNumber"
        element={<AuthorizedStoreColleagueComponent component={RangeAndSpace} />}
      />
      <Route
        path=":storeNumber/upcoming"
        element={<Navigate to="/range-and-space/:storeNumber" />}
      />
      <Route
        path=":storeNumber/current"
        element={<Navigate to="/range-and-space/:storeNumber" />}
      />
      <Route
        path=":storeNumber/previous"
        element={<Navigate to="/range-and-space/:storeNumber" />}
      />
      <Route
        path=":storeNumber/*"
        element={<Navigate to="/range-and-space/:storeNumber/current" />}
      />
    </Route>
  </>
)

export default route
