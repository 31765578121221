import { combineReducers, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { GetUserRequestedInfo, GetUserInfo, PatchUserInfo } from 'src/api/User'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  buildThunkPrefix,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'UserDetail'
const USER_INFO_NAME = 'UserInfo'
const USERREQUESTED_INFO = 'UserRequestedInfo'
const STORE_PATH = `modules/${NAMESPACE}`

const selectSelf = state => state.modules.UserDetail
const selectUserInfo = createSelector(selectSelf, module => module?.UserInfo?.data)
const selectUserRequestedInfo = createSelector(
  selectSelf,
  module => module?.UserRequestedInfo?.data
)
const selectIsNewUserRequestedStatus = createSelector(
  selectSelf,
  module => module?.UserRequestedInfo?.isNewRequest
)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const actionUpdateUser = createAsyncThunk(
  buildThunkPrefix(USER_INFO_NAME, 'updateUserInfo'),
  async ({ userId, user, rejected }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchUserInfoInstance = new PatchUserInfo(store, {
      params: { id: userId, user, rejected },
    })

    const response = await handleCreateAsyncThunkResult(
      patchUserInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
function updateUser(userId, user, rejected) {
  // need to pass it as single arg as it goes to createAsyncThunk
  return actionUpdateUser({ userId, user, rejected })
}

const fetchUserInfo = createAsyncThunk(
  buildThunkPrefix(USER_INFO_NAME, 'fetchUserInfo'),
  async (userId, { getState, dispatch, rejectWithValue }) => {
    try {
      const store = getState()
      const fetchUserInfoInstance = new GetUserInfo(store, {
        params: { id: userId },
      })

      const response = await handleCreateAsyncThunkResult(
        fetchUserInfoInstance,
        dispatch,
        rejectWithValue
      )
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

const fetchUserRequestedInfo = createAsyncThunk(
  buildThunkPrefix(USERREQUESTED_INFO, 'fetchUserRequestedInfo'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    try {
      const store = getState()
      const fetchUserRequestedInstance = new GetUserRequestedInfo(store, {
        params: { id },
      })

      const response = await handleCreateAsyncThunkResult(
        fetchUserRequestedInstance,
        dispatch,
        rejectWithValue
      )
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

const userInfoSlice = createSlice({
  name: USER_INFO_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserInfo.pending, pendingDataHandler)
    builder.addCase(fetchUserInfo.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchUserInfo.rejected, rejectedDataHandler)
  },
})

const userRequestedInfoSlice = createSlice({
  name: USERREQUESTED_INFO,
  initialState,
  reducers: {
    isNewUserRequested: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isNewRequest = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserRequestedInfo.pending, pendingDataHandler)
    builder.addCase(fetchUserRequestedInfo.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchUserRequestedInfo.rejected, rejectedDataHandler)
  },
})

export {
  updateUser,
  actionClearData,
  fetchUserInfo,
  fetchUserRequestedInfo,
  selectUserRequestedInfo,
  selectUserInfo,
  selectIsNewUserRequestedStatus,
}
export const { isNewUserRequested } = userRequestedInfoSlice.actions
export default {
  [NAMESPACE]: combineReducers({
    [userInfoSlice.name]: userInfoSlice.reducer,
    [userRequestedInfoSlice.name]: userRequestedInfoSlice.reducer,
  }),
}
