import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import NominationsList from 'src/modules/Nominations'
import NominationsDetail from 'src/modules/NominationsDetail'

const route = (
  <>
    <Route path="/nominations" element={<AuthorizedComponent component={NominationsList} />} />
    <Route
      path="/nominations/create"
      element={<AuthorizedComponent component={NominationsDetail} />}
    />
    <Route
      path="/nominations/:id"
      element={<AuthorizedComponent component={NominationsDetail} />}
    />
  </>
)

export default route
