import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

const buildDataControl = (title, parentTitle) => {
  const combindedTitle = parentTitle ? `${parentTitle}-${title}` : title
  return `nav-${String(combindedTitle).toLowerCase().replace(' ', '-')}`
}

const HeaderLink = ({ className, to, title, parentTitle }) => (
  <NavLink
    className={({ isActive }) => classNames(className, { 'is-active': isActive })}
    to={to}
    data-control={buildDataControl(title, parentTitle)}
  >
    {title}
  </NavLink>
)

HeaderLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  parentTitle: PropTypes.string,
}

HeaderLink.defaultProps = {
  className: '',
  parentTitle: null,
}

export default HeaderLink
