import React from 'react'
import { Card, Heading2, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/react'
import Section from 'src/components/Section'
import Table from 'src/components/Table'
import { Link } from 'react-router-dom'
import { CREATE_GROUPS } from 'src/constants/permissions'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import columns from './columns'
import { selectGroups } from './store'

const Groups = () => (
  <Section>
    <FlagWrapper>
      <FlagBody>
        <Heading2 className="ln-u-soft-right" data-control="groups-title">
          Groups
        </Heading2>
      </FlagBody>
      <FlagComponent alignment="top" nowrap>
        <WithRequiredPermissions permission={CREATE_GROUPS}>
          <Link className="ln-c-button ln-c-button--outlined" to="/groups/create">
            Create
          </Link>
        </WithRequiredPermissions>
      </FlagComponent>
    </FlagWrapper>
    <Card>
      <Table type="groups" searchEnabled columns={columns} dataSelector={selectGroups} />
    </Card>
  </Section>
)

export default Groups
