import {
  BrowserAuthError,
  BrowserAuthErrorMessage,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'
import LoginScope from 'src/utils/loginScope'
import { ACCESSING_TOKEN_STATUS, FAILURE, IN_PROGRESS } from '../constants/api'

function createLoginHandler(
  callback,
  isStoreColleagueView,
  instance,
  accounts,
  inProgress,
  dispatch
) {
  return function loginHandler({ location }) {
    const params = new URL(location).searchParams
    let redirect = params.get('redirect') || location.pathname

    if (!redirect || redirect.includes('login')) {
      redirect = isStoreColleagueView ? '/range-and-space' : '/space-plans'
    }

    const accessTokenRequest = {
      scopes: [LoginScope()],
      account: Array.isArray(accounts) ? accounts[0] ?? null : null,
      redirectUri: isStoreColleagueView
        ? `${location.origin}/login-store-colleague`
        : `${location.origin}/login`,
    }

    if (inProgress === InteractionStatus.None) {
      dispatch({ type: ACCESSING_TOKEN_STATUS, payload: IN_PROGRESS })
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          // Acquire token silent success
          const { accessToken } = accessTokenResponse

          callback({
            redirect,
            accessToken: { mainToken: accessToken },
          })
        })
        .catch(error => {
          dispatch({ type: ACCESSING_TOKEN_STATUS, payload: FAILURE })
          if (
            error instanceof BrowserAuthError &&
            error.errorCode === BrowserAuthErrorMessage.noAccountError.code
          ) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
        })
    }

    return null
  }
}

export default createLoginHandler
