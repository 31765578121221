import {
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  initialState,
  pendingDataHandler,
  rejectedDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  PostShelfProduct,
  PatchShelfProduct,
  DeleteShelfProductsByIds,
  GetShelfProductById,
} from 'src/api/ShelfProduct'

const NAMESPACE = 'ShelfProduct'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionCreateShelfProduct = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'createShelfProduct'),
  async (shelfProduct, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postShelfProductInstance = new PostShelfProduct(store, {
      params: shelfProduct,
    })
    const response = await handleCreateAsyncThunkResult(
      postShelfProductInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionDeleteShelfProductsByIds = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteShelfProductsByIds'),
  async (productsToDeleteByIds, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteShelfProductsByIdsInstance = new DeleteShelfProductsByIds(store, {
      params: { productsToDeleteByIds },
    })
    const response = await handleCreateAsyncThunkResult(
      deleteShelfProductsByIdsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateShelfProduct = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateShelfProduct'),
  async ({ shelfProductId, shelfProduct }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchShelfProductInstance = new PatchShelfProduct(store, {
      params: { shelfProductId, shelfProduct },
    })
    const response = await handleCreateAsyncThunkResult(
      patchShelfProductInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchShelfProductById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchShelfProductById'),
  async (shelfProductId, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const getShelfProductInstance = new GetShelfProductById(store, {
      params: { shelfProductId },
    })
    const response = await handleCreateAsyncThunkResult(
      getShelfProductInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const shelfProductByIdSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchShelfProductById.pending, pendingDataHandler)
    builder.addCase(fetchShelfProductById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchShelfProductById.rejected, rejectedDataHandler)
  },
})

export {
  actionCreateShelfProduct,
  actionUpdateShelfProduct,
  actionDeleteShelfProductsByIds,
  fetchShelfProductById,
  shelfProductByIdSlice,
}
