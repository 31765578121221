import React from 'react'
import { connect } from 'react-redux'
import { UPDATE_GROUPS } from 'src/constants/permissions'
import { hasRequiredPermission } from 'src/utils/permissions'

import { useNavigate } from 'react-router-dom'
import {
  actionCreateGroup,
  actionUpdateGroup,
  selectGroup,
  selectSpaces,
  selectSpacePlans,
  fetchSpaces,
} from '../store'
import Form from './Form'

export const mapStateToProps = (state, { user = {} }) => {
  const allSpaces = selectSpaces(state) || []
  const allSpacePlans = selectSpacePlans(state) || []
  const selectedGroup = selectGroup(state) || {}
  const isReadOnly = !hasRequiredPermission(user, UPDATE_GROUPS)
  const isNew = !selectedGroup?.id
  const group = {
    id: isNew ? null : selectedGroup.id,
    title: isNew ? '' : selectedGroup.title,
    groupSpaceIds: isNew ? [] : selectedGroup.spaces?.map(item => item.id),
    selectedSpaces: isNew ? [] : selectedGroup.spaces,
    spacePlanId: isNew ? '' : selectedGroup.spacePlan?.id,
  }
  return {
    group,
    allSpaceIds: [...allSpaces],
    spacePlans: [...allSpacePlans],
    isReadOnly,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/groups')
  }

  const mapDispatchToProps = dispatch => ({
    submit: async group => {
      const action = group.id ? actionUpdateGroup(group) : actionCreateGroup(group)
      await dispatch(action)
      returnAction()
    },
    onChange: async (spacePlanId, groupId) => {
      await dispatch(fetchSpaces({ spacePlanId, groupId }))
    },
    cancel: () => returnAction(),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
