import React from 'react'
import { Route } from 'react-router-dom'
import Categories from 'src/modules/Categories'
import CategoryDetail from 'src/modules/CategoryDetails'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'

const route = (
  <>
    <Route path="/categories" element={<AuthorizedComponent component={Categories} />} />
    <Route path="/categories/create" element={<AuthorizedComponent component={CategoryDetail} />} />
    <Route path="/categories/:id" element={<AuthorizedComponent component={CategoryDetail} />} />
  </>
)

export default route
