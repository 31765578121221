import React from 'react'
import { Route } from 'react-router-dom'
import UsersList from 'src/modules/Users'
import UsersDetail from 'src/modules/UserDetail'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'

const Routes = (
  <>
    <Route path="/users" element={<AuthorizedComponent component={UsersList} />} />
    <Route path="/users/:id" element={<AuthorizedComponent component={UsersDetail} />} />
  </>
)

export default Routes
