import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Heading3 } from '@jsluna/react'

import { CREATE_SPACE_TYPES } from 'src/constants/permissions'

import Section from 'src/components/Section'
import Table from 'src/components/Table'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import { selectAllSpaceTypes } from './store'
import columns from './columns'

const Spaces = () => (
  <div data-control="space-types-list">
    <Section>
      <Card>
        <Heading3 className="ln-u-visually-hidden">Spaces Types List</Heading3>
        <div className="ln-u-push-bottom">
          <WithRequiredPermissions permission={CREATE_SPACE_TYPES}>
            <Link className="ln-c-button ln-c-button--outlined" to="/spaces/types/create">
              Create
            </Link>
          </WithRequiredPermissions>
        </div>
        <Table
          type="spaceTypes"
          searchEnabled
          columns={columns}
          dataSelector={selectAllSpaceTypes}
        />
      </Card>
    </Section>
  </div>
)

export default Spaces
