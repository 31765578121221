import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actionUpdateCycle, actionCreateCycle, fetchLatestCycleByGroup } from '../../store'
import Form from './Form'
import mapStateToProps from './mapStateToProps'

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = businessUnit => {
    navigate(`/cycles/${businessUnit}`)
  }

  const mapDispatchToProps = (dispatch, { businessUnit }) => ({
    submit: async (cycle, id) => {
      const action = id ? actionUpdateCycle({ id, cycle }) : actionCreateCycle(cycle)
      await dispatch(action)
      returnAction(businessUnit)
    },
    onChange: async groupId => {
      await dispatch(fetchLatestCycleByGroup({ businessUnit, groupId })).unwrap()
    },
    cancel: () => returnAction(businessUnit),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
