import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const GenericError = ({ message }) => (
  <Section>
    <Heading2>Something went wrong!</Heading2>
    <p>An error occurred trying to perform that action.</p>
    <p>
      If you are repeatedly seeing this message, please contact your support team with the details
      below.
    </p>
    <pre className="u-pre-wrap">{message}</pre>
    <Link to="/">Back to dashboard</Link>
  </Section>
)

GenericError.propTypes = {
  message: PropTypes.string,
}

GenericError.defaultProps = {
  message: 'Unknown Error',
}

export default GenericError
