import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import GroupsList from 'src/modules/Groups'
import GroupsDetail from 'src/modules/GroupsDetail'

const route = (
  <>
    <Route path="/groups" element={<AuthorizedComponent component={GroupsList} />} />
    <Route path="/groups/create" element={<AuthorizedComponent component={GroupsDetail} />} />
    <Route path="/groups/:id" element={<AuthorizedComponent component={GroupsDetail} />} />
  </>
)

export default route
