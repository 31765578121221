import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { DATE_FORMAT } from 'src/utils/datePeriod'

import hfssStatus from 'src/functions/spaceHfssStatus'
import Categories from './Categories'
import Tagged from './Tagged'

const getLink = spaceAssignment => `/space-assignments/${spaceAssignment.id}`
const getTitle = spaceAssignment => spaceAssignment.theme || 'N/A'
const getSpaceTitle = spaceAssignment => spaceAssignment.spaceTitle

const getPropositionObject = spaceAssignment =>
  spaceAssignment.tagged !== ''
    ? {
        active: true,
        theme: '#000000',
        id: spaceAssignment.id,
        title: 'Proposition Space',
      }
    : null

const formatStartDate = spaceAssignment =>
  moment(spaceAssignment.startDate).tz('Europe/London').format(DATE_FORMAT)

const formatEndDate = spaceAssignment =>
  moment(spaceAssignment.endDate).tz('Europe/London').format(DATE_FORMAT)

const SpaceLinkCell = ({ value, link }) => (
  <Link to={link} className="ln-c-table__link">
    {value}
  </Link>
)
SpaceLinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default [
  {
    name: 'Theme',
    accessor: rowData => ({ value: getTitle(rowData), link: getLink(rowData) }),
    render: SpaceLinkCell,
  },
  {
    name: 'Space Name',
    accessor: rowData => ({ value: getSpaceTitle(rowData), link: getLink(rowData) }),
    sort: (accessor, ascending) => (a, b) => {
      const valueA = accessor(a).value
      const valueB = accessor(b).value

      const comparisonResult = valueA.localeCompare(valueB, undefined, {
        numeric: true,
        sensitivity: 'base',
      })

      return ascending ? comparisonResult : comparisonResult * -1
    },
    render: SpaceLinkCell,
  },
  {
    name: 'HFSS',
    accessor: rowData => ({ value: hfssStatus(rowData.hfss) }),
  },
  {
    name: 'Tagged',
    accessor: rowData => {
      const proposition = getPropositionObject(rowData)
      const tagged = [...(proposition ? [proposition] : [])]

      return {
        value: Tagged(tagged),
        tagged,
      }
    },
    sort: (accessor, ascending) => (a, b) => {
      const objectA = accessor(a).tagged[0]
      const objectB = accessor(b).tagged[0]

      const valueA = objectA ? objectA.title : ''
      const valueB = objectB ? objectB.title : ''

      if (valueA < valueB) {
        return ascending ? -1 : 1
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1
      }
      return 0
    },
  },
  {
    name: 'Start Date',
    accessor: rowData => ({ value: formatStartDate(rowData), rawDate: rowData.startDate }),
    sort: (accessor, ascending) => (a, b) => {
      const valueA = moment(accessor(a).rawDate)
      const valueB = moment(accessor(b).rawDate)

      if (valueA < valueB) {
        return ascending ? -1 : 1
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1
      }
      return 0
    },
  },
  {
    name: 'End Date',
    accessor: rowData => ({ value: formatEndDate(rowData), rawDate: rowData.endDate }),
    sort: (accessor, ascending) => (a, b) => {
      const valueA = moment(accessor(a).rawDate)
      const valueB = moment(accessor(b).rawDate)

      if (valueA < valueB) {
        return ascending ? -1 : 1
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1
      }
      return 0
    },
  },
  {
    name: 'Categories',
    accessor: rowData => ({ value: Categories(rowData), categories: rowData.categories }),
    sort: (accessor, ascending) => (a, b) => {
      const objectA = accessor(a).categories[0]
      const objectB = accessor(b).categories[0]

      const valueA = objectA ? objectA.title : ''
      const valueB = objectB ? objectB.title : ''

      if (valueA < valueB) {
        return ascending ? -1 : 1
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1
      }
      return 0
    },
  },
]
