import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { GetGroups } from 'src/api/Group'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'Groups'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const selectSelf = state => state.modules.Groups
const selectGroups = createSelector(selectSelf, module => module.data)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchGroups = createAsyncThunk(
  buildThunkPrefix('fetchGroups'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchGroupsInstance = new GetGroups(store)

    const response = await handleCreateAsyncThunkResult(
      fetchGroupsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const groupsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGroups.pending, pendingDataHandler)
    builder.addCase(fetchGroups.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchGroups.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchGroups }
export { selectGroups }

export default { [NAMESPACE]: groupsSlice.reducer }
