import React from 'react'
import PropTypes from 'prop-types'
import { Heading2, List, ListItem, FilledButton } from '@jsluna/react'

import UploadForm from './UploadForm'
import ProductsList from './ProductsList'
import UploadButton from './UploadButton'

const NominationsUpload = ({
  products,
  status: { message, isValid, isUploading, isFinished },
  updateStatus,
  importProducts,
  uploadProducts,
  handleClose,
}) => (
  <div>
    <Heading2>Nominations Upload</Heading2>
    <UploadForm onStatusChange={updateStatus} onUpload={importProducts} />
    <div className="ln-u-push-bottom">
      <strong>Status: </strong> {message}
    </div>
    <ProductsList products={products} />
    <List type="matrix">
      <ListItem type="matrix">
        <UploadButton
          isValid={isValid}
          isUploading={isUploading}
          isFinished={isFinished}
          onClick={uploadProducts}
        />
      </ListItem>
      <ListItem type="matrix">
        <FilledButton color="dark" onClick={handleClose}>
          Cancel
        </FilledButton>
      </ListItem>
    </List>
  </div>
)

NominationsUpload.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  status: PropTypes.shape({
    message: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    isUploading: PropTypes.bool.isRequired,
    isFinished: PropTypes.bool.isRequired,
  }).isRequired,
  updateStatus: PropTypes.func.isRequired,
  importProducts: PropTypes.func.isRequired,
  uploadProducts: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default NominationsUpload
