import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  buildThunkPrefix,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { PostSpaceGroup, GetSpaceGroupById, PatchSpaceGroupById } from 'src/api/Space'

const NAMESPACE = 'SpaceGroupDetail'
const SPACEGROUPINFO = 'SpaceGroupInfo'

const selectModules = state => state.modules.SpaceGroupDetail
const selectSpaceGroupByIdInfo = createSelector(selectModules, module => module.SpaceGroupInfo.data)
const actionClearData = actionClearDataHandler(NAMESPACE)

const actionCreateSpaceGroup = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionCreateSpaceGroup'),
  async (spaceGroup, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postSpaceGroupInstance = new PostSpaceGroup(store, {
      params: { spaceGroup },
    })

    const response = await handleCreateAsyncThunkResult(
      postSpaceGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateSpaceGroupById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateSpaceGroupById'),
  async ({ spaceGroupId, spaceGroup }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchSpaceGroupInstance = new PatchSpaceGroupById(store, {
      params: { spaceGroupId, spaceGroup },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchSpaceGroupById = createAsyncThunk(
  buildThunkPrefix(SPACEGROUPINFO, 'fetchSpaceGroupById'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const getSpaceGroupInstance = new GetSpaceGroupById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getSpaceGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spaceGroupByIdSlice = createSlice({
  name: SPACEGROUPINFO,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceGroupById.pending, pendingDataHandler)
    builder.addCase(fetchSpaceGroupById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceGroupById.rejected, rejectedDataHandler)
  },
})

export {
  selectSpaceGroupByIdInfo,
  actionClearData,
  actionCreateSpaceGroup,
  actionUpdateSpaceGroupById,
  fetchSpaceGroupById,
}

export default {
  [NAMESPACE]: combineReducers({
    [spaceGroupByIdSlice.name]: spaceGroupByIdSlice.reducer,
  }),
}
