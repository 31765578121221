import React from 'react'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { zoomIn, zoomOut, toggleOpen, toggleTrackOpen } from '../store/actions'
import Timeline from './Timeline'

const TimelineContainer = props => {
  const navigate = useNavigate()

  const mapDispatchToProps = {
    zoomIn,
    zoomOut,
    toggleOpen,
    toggleTrackOpen,
    redirect: navigate,
  }

  const ConnectedTimeline = connect(null, mapDispatchToProps)(Timeline)

  return <ConnectedTimeline {...props} />
}

export default TimelineContainer
