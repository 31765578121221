import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import MessagesAdmin from 'src/modules/MessagesAdmin'

const route = (
  <Route path="/admin-config" element={<AuthorizedComponent component={MessagesAdmin} />} />
)

export default route
