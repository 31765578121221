import React from 'react'
import PropTypes from 'prop-types'

import ShipperCompliance from 'src/modules/ShipperCompliance'

const Component = ({ params: { id: cycleId } }) => <ShipperCompliance cycleId={cycleId} />

Component.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default Component
