import { createSelector } from 'reselect'

import { buildReducer, bindSelector } from 'src/store/modules'
import sortNatural from 'src/utils/sortNatural'

import {
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetChangeControlsByCycleId,
  ExportAllChangeControlsByCycleId,
  ExportChangeControlSkuDetailsByCycleId,
} from 'src/api/ChangeControl'
import uiReducer from './reducer'

const NAMESPACE = 'ChangeControls'
const CHANGECONTROLSBYCYCLEID = 'ChangeControlsByCycleId'
const STORE_PATH = `modules/${NAMESPACE}`
const TITLE = 'title'
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const selectSelf = state => state.modules.ChangeControls?.ChangeControlsByCycleId
const selectChangeControls = createSelector(selectSelf, module => module?.data?.changeControls)
const selectFilters = createSelector(selectSelf, module => {
  const changeControl = module?.data
  const reasonCodeFilters = sortNatural([...changeControl?.reasonOptions], TITLE) || []
  const spaceFilters = sortNatural([...changeControl?.spaceOptions], TITLE) || []
  const statusFilters = changeControl?.statusOptions || []

  return {
    reasonCodeFilters,
    spaceFilters,
    statusFilters,
  }
})
const selectChangeControlById = (state, changeControlId) =>
  createSelector(
    selectChangeControls,
    changeControlDetails =>
      changeControlDetails.find(changeControl => changeControl.id === changeControlId) || {}
  )(state)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchChangeControlsByCycleId = createAsyncThunk(
  buildThunkPrefix(CHANGECONTROLSBYCYCLEID, 'fetchChangeControlsByCycleId'),
  async ({ cycleId }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetChangeControlsByCycleIdInstance = new GetChangeControlsByCycleId(store, {
      params: { cycleId },
    })
    const response = await handleCreateAsyncThunkResult(
      GetChangeControlsByCycleIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const changeControlsByCycleIdSlice = createSlice({
  name: CHANGECONTROLSBYCYCLEID,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchChangeControlsByCycleId.pending, pendingDataHandler)
    builder.addCase(fetchChangeControlsByCycleId.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchChangeControlsByCycleId.rejected, rejectedDataHandler)
  },
})

const selectUi = bindSelector(NAMESPACE)(state => state.ui)

const filterByStatus = (changeControls, statusSelected) =>
  changeControls.filter(({ status }) => status.toLowerCase() === statusSelected)

const filterByReasonCode = (changeControls, reasonCode) =>
  changeControls.filter(
    ({ changeControlReasonCode }) => changeControlReasonCode.toLowerCase() === reasonCode
  )

const filterBySpace = (changeControls, spaceTitleSelected) =>
  changeControls.filter(({ spaceTitle }) => spaceTitle.toLowerCase() === spaceTitleSelected)

const selectFilterApplied = createSelector(selectUi, ({ filters }) =>
  Object.values(filters).some(filter => filter)
)

const selectFilteredChangeControls = createSelector(
  selectChangeControls,
  selectUi,
  (changeControls, ui) => {
    let filteredChangeControls = changeControls

    const {
      filters: { space, reasonCode, status },
    } = ui

    filteredChangeControls = status
      ? filterByStatus(filteredChangeControls, status)
      : filteredChangeControls

    filteredChangeControls = reasonCode
      ? filterByReasonCode(filteredChangeControls, reasonCode)
      : filteredChangeControls

    filteredChangeControls = space
      ? filterBySpace(filteredChangeControls, space)
      : filteredChangeControls

    return filteredChangeControls
  }
)

const actionGetSummaryData = async ({ cycleId }, dispatch) => {
  await dispatch(fetchChangeControlsByCycleId({ cycleId }))
}

const selectModule = state => state.modules.ChangeControls
const selectExportsAllChangeControls = createSelector(selectModule, module => module.data)

const fetchExportsAllChangeControls = createAsyncThunk(
  NAMESPACE,
  async (cycleId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const exportAllChangeControls = new ExportAllChangeControlsByCycleId(store, {
      params: cycleId,
    })

    const response = await handleCreateAsyncThunkResult(
      exportAllChangeControls,
      dispatch,
      rejectWithValue,
      false
    )
    return response
  }
)

const selectExportsSkuDetails = createSelector(selectModule, module => module.data)

const fetchExportChangeControlSkuDetails = createAsyncThunk(
  NAMESPACE,
  async (cycleId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const exportAllChangeControlss = new ExportChangeControlSkuDetailsByCycleId(store, {
      params: cycleId,
    })

    const response = await handleCreateAsyncThunkResult(
      exportAllChangeControlss,
      dispatch,
      rejectWithValue,
      false
    )
    return response
  }
)
const exportAllSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchExportsAllChangeControls.pending, pendingDataHandler)
    builder.addCase(fetchExportsAllChangeControls.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchExportsAllChangeControls.rejected, rejectedDataHandler)
  },
})

const exportSkuDetailSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchExportChangeControlSkuDetails.pending, pendingDataHandler)
    builder.addCase(fetchExportChangeControlSkuDetails.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchExportChangeControlSkuDetails.rejected, rejectedDataHandler)
  },
})

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [changeControlsByCycleIdSlice.name]: changeControlsByCycleIdSlice.reducer,
  [exportAllSlice.name]: exportAllSlice.reducer,
  [exportSkuDetailSlice.name]: exportSkuDetailSlice.reducer,
})

export {
  selectFilterApplied,
  selectFilteredChangeControls,
  actionClearData,
  actionGetSummaryData,
  fetchChangeControlsByCycleId,
  selectChangeControls,
  selectChangeControlById,
  selectFilters,
  fetchExportsAllChangeControls,
  fetchExportChangeControlSkuDetails,
  selectExportsAllChangeControls,
  selectExportsSkuDetails,
}
