import React from 'react'
import { connect } from 'react-redux'

import { useNavigate } from 'react-router-dom'
import { selectSpaceGroupByIdInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const spaceGroup = selectSpaceGroupByIdInfo(state, id) || {}
  const { description = '', position = 0, title = '' } = spaceGroup
  return {
    ...spaceGroup,
    id,
    originalTitle: title,
    description,
    position: String(position),
    title,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/spaces/groups')
  }

  const mapDispatchToProps = dispatch => ({
    afterSubmit: () => returnAction(),
    cancel: () => returnAction(),
    submit: action => dispatch(action),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
