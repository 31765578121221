import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hasRequiredPermission } from 'src/utils/permissions'
import { selectSelf } from 'src/store/data/selectors'

export function Component({ permission, user, children = null }) {
  return hasRequiredPermission(user, permission) ? children : null
}

Component.propTypes = {
  permission: PropTypes.string.isRequired,
  user: PropTypes.shape({}),
  children: PropTypes.node,
}

Component.defaultProps = {
  user: {},
  children: null,
}

const mapStateToProps = state => ({
  user: selectSelf(state),
})

export default connect(mapStateToProps)(Component)
