import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { StatusCard } from '@jsluna/react'

const PendingChangeControlCard = ({ createdBy, id, isChangeControlView, spaceAssignmentId }) => (
  <StatusCard className="ln-u-push-bottom" status="danger">
    {isChangeControlView ? (
      <>
        <Link
          data-control="link-space-assignment-view"
          className="ln-c-button ln-c-button--link"
          to={`/space-assignments/${spaceAssignmentId}`}
        >
          Click here
        </Link>{' '}
        to view the space without any changes.{' '}
      </>
    ) : (
      <>
        There is an existing change control request on this space, with changes created by{' '}
        {createdBy}, please{' '}
        <Link
          data-control="link-change-control-view"
          className="ln-c-button ln-c-button--link"
          to={`/space-assignments/${spaceAssignmentId}/change-controls/${id}`}
        >
          click here
        </Link>{' '}
        to view the changes.{' '}
      </>
    )}
    To review the change control, please{' '}
    <Link className="ln-c-button ln-c-button--link" to={`/change-controls/${id}`} target="_blank">
      click here
    </Link>
    .
  </StatusCard>
)

PendingChangeControlCard.propTypes = {
  createdBy: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isChangeControlView: PropTypes.bool.isRequired,
  spaceAssignmentId: PropTypes.string.isRequired,
}

export default PendingChangeControlCard
