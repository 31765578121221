import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { Tabs as LunaTabs, TabLink as LunaTab } from '@jsluna/react'

const isTabActive = (tabPath, pathName, pattern) =>
  pattern ? pattern.test(pathName) : pathName.endsWith(tabPath)

const buildTabs = (tabs, props) =>
  tabs &&
  tabs
    .map(tab => ({
      ...tab,
      link: tab.link(props),
      pattern: tab.pattern ? tab.pattern(props) : null,
      isVisible: tab.isVisible(props),
    }))
    .filter(({ isVisible }) => !!isVisible)

export const TabsWrapper = ({ tabs, control, location, ...rest }) => (
  <LunaTabs className="u-print-hidden">
    {buildTabs(tabs, rest).map(tab => {
      const { id: tabId, title, link, pattern } = tab
      const dataControl = control ? `${control}-tab-${tabId}` : `tab-${tabId}`

      return (
        <LunaTab
          key={link}
          element={<Link to={link} />}
          active={isTabActive(link, location.pathname, pattern)}
        >
          <span data-control={dataControl}>{title}</span>
        </LunaTab>
      )
    })}
  </LunaTabs>
)

TabsWrapper.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  control: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

TabsWrapper.defaultProps = {
  control: undefined,
}

const buildTabsWrapper = (tabs, control) => {
  return props => {
    const location = useLocation()
    return <TabsWrapper {...props} tabs={tabs} control={control} location={location} />
  }
}

export default buildTabsWrapper
