import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '@jsluna/react'

import Form from './Form'

const Component = ({ params: { id, businessUnit }, authData: { user } }) => (
  <Card>
    <Form id={id} businessUnit={businessUnit} user={user} />
  </Card>
)

Component.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
  }),
  authData: PropTypes.shape({
    user: PropTypes.shape({}).isRequired,
  }).isRequired,
}

Component.defaultProps = {
  params: {
    id: null,
  },
}

export default Component
