import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { hasPermission } from 'src/utils/permissions'

import { selectSelf } from 'src/store/data/selectors'

export function Component({
  user,
  permission,
  entityCategories,
  entityOwnerId,
  shouldDisplay,
  children = null,
}) {
  return shouldDisplay && hasPermission(user, permission, entityCategories, entityOwnerId)
    ? children
    : null
}

Component.propTypes = {
  entityCategories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  entityOwnerId: PropTypes.string,
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  shouldDisplay: PropTypes.bool,
  children: PropTypes.node,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  }),
}

Component.defaultProps = {
  entityCategories: [],
  entityOwnerId: null,
  shouldDisplay: true,
  user: null,
  children: null,
}

Component.displayName = 'WithPermission'

const mapStateToProps = state => ({
  user: selectSelf(state),
})

export default connect(mapStateToProps)(Component)
