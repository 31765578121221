import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, Heading3 } from '@jsluna/react'

import { createNotification } from 'src/store/notifications/actions'

import Section from 'src/components/Section'

import { useNavigate } from 'react-router-dom'
import mapStateToProps from './mapStateToProps'
import Form from './Form'
import ReadOnlyDetails from './ReadOnly'
import {
  actionDeleteSpaceAssignment,
  actionCreateSpaceAssignment,
  actionUpdateSpaceAssignmentById,
  fetchSpacePlanAssignment,
} from '../store/reducer/spacePlanAssignment'

const Details = ({ isReadOnly, ...props }) => (
  <Section>
    <Heading3>Details</Heading3>
    <Card>{isReadOnly ? <ReadOnlyDetails {...props} /> : <Form {...props} />}</Card>
  </Section>
)

Details.propTypes = {
  isReadOnly: PropTypes.bool,
}

Details.defaultProps = {
  isReadOnly: true,
}

const DetailsContainer = props => {
  const navigate = useNavigate()

  const returnAction = rt => {
    if (rt) {
      return navigate(rt)
    }

    return navigate('/space-plans/')
  }

  const mapDispatchToProps = (dispatch, { query }) => ({
    submit: async (assignment, id) => {
      const action = id
        ? actionUpdateSpaceAssignmentById({ id, assignment })
        : actionCreateSpaceAssignment(assignment)
      await dispatch(action).unwrap()
      if (id) {
        dispatch(createNotification('Space assignment saved'))
        dispatch(fetchSpacePlanAssignment({ id }))
      } else {
        navigate(-1)
      }
    },
    deleteSpaceAssignment: async id => {
      const { rt = '' } = query
      await dispatch(actionDeleteSpaceAssignment(id))

      returnAction(rt)
    },
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Details)

  return <ConnectedForm {...props} />
}

export default DetailsContainer
