import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCategoryByIdInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const category = (id && selectCategoryByIdInfo(state, id)) || {}
  const {
    title = '',
    shortTitle = '',
    theme = '',
    businessUnit,
    isPriceLock = false,
    active = false,
  } = category

  return {
    ...category,
    id,
    title,
    shortTitle,
    businessUnit,
    theme,
    isPriceLock,
    active,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/categories')
  }

  const mapDispatchToProps = dispatch => ({
    afterSubmit: () => returnAction(),
    cancel: () => returnAction(),
    submit: action => dispatch(action),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
