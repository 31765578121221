import { buildReducer } from 'src/store/modules'
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  GetSharePointNominationById,
  UpdateSharepointNomination,
} from 'src/api/SharePointNomination'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'NominationsDetail'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = (slice, thunkName) => `${STORE_PATH}/${slice}/${thunkName}`

const selectNominationDetails = state => state.modules.NominationsDetail.details.data

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchNominationDetails = createAsyncThunk(
  buildThunkPrefix('details', 'fetchNominationDetails'),
  async (nominationId, { getState, dispatch, rejectWithValue }) => {
    try {
      const store = getState()
      const fetchNominationDetailsInstance = new GetSharePointNominationById(store, {
        params: { id: nominationId },
      })
      const response = await handleCreateAsyncThunkResult(
        fetchNominationDetailsInstance,
        dispatch,
        rejectWithValue
      )
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)
const actionUpdateSharepointNomination = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateSharepointNomination'),
  async ({ id, status }, { getState, dispatch, rejectWithValue }) => {
    try {
      const store = getState()
      const patchSharepointNominationInstance = new UpdateSharepointNomination(store, {
        params: { id, status },
      })
      const response = await handleCreateAsyncThunkResult(
        patchSharepointNominationInstance,
        dispatch,
        rejectWithValue
      )
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

const nominationDetailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNominationDetails.pending, pendingDataHandler)
    builder.addCase(fetchNominationDetails.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchNominationDetails.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchNominationDetails, actionUpdateSharepointNomination }
export { selectNominationDetails }

export default buildReducer(NAMESPACE, {
  [nominationDetailsSlice.name]: nominationDetailsSlice.reducer,
})
