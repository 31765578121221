import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Header as LunaHeader,
  HeaderGlobalBar,
  HeaderGlobalItem,
  HeaderLogo,
  HeaderMainBar,
  HeaderNav,
  ListGroup,
  ListGroupItem,
  ListGroupDropdown,
} from '@jsluna/react'

import HeaderLink from './HeaderLink'

const buildDataControl = (title, parentTitle) => {
  const combindedTitle = parentTitle ? `${parentTitle}-${title}` : title
  return `nav-${String(combindedTitle).toLowerCase().replace(' ', '-')}`
}

const Header = ({ menu, role }) => {
  const [active, setActive] = useState(false)
  return (
    <LunaHeader divided>
      <HeaderGlobalBar label="Group navigation">
        <HeaderGlobalItem element="div" align="right">
          <strong>Role</strong>{' '}
          <Link
            to="/profile"
            className="ln-o-bare-link"
            title="View profile"
            data-control="profile-link"
          >
            {role}
          </Link>
        </HeaderGlobalItem>
      </HeaderGlobalBar>
      <HeaderMainBar size="lg">
        <HeaderLogo>
          <Link to="/" title="Home">
            <span className="ln-c-navigation-wrapper__title">PromComm</span>
          </Link>
        </HeaderLogo>
        <HeaderNav drawer label="Main">
          {menu.map(menuItem => {
            if (menuItem.items) {
              const items = menuItem.items.map(({ path, title }) => (
                <ListGroupItem
                  element={Link}
                  to={path}
                  key={title}
                  title={title}
                  parenttitle={menuItem.title}
                  data-control={buildDataControl(title, menuItem.title)}
                  activeClassName="is-active"
                >
                  {title}
                </ListGroupItem>
              ))

              return (
                <ListGroupDropdown
                  id={menuItem.title}
                  key={menuItem.title}
                  label={menuItem.title}
                  data-control={`nav-${String(menuItem.title).toLowerCase().replace(' ', '-')}`}
                >
                  {menuItem.title}
                  <ListGroup
                    active={+active}
                    onClick={e => {
                      e.preventDefault()
                      setActive(!active)
                    }}
                  >
                    {items.map(item => item)}
                  </ListGroup>
                </ListGroupDropdown>
              )
            }

            return <HeaderLink key={menuItem.title} to={menuItem.path} title={menuItem.title} />
          })}
        </HeaderNav>
      </HeaderMainBar>
    </LunaHeader>
  )
}

Header.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  role: PropTypes.string,
}

Header.defaultProps = {
  role: null,
}

export default Header
