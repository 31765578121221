import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  buildThunkPrefix,
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetSpaceGroups } from 'src/api/Space'
import { createSelector } from 'reselect'

const NAMESPACE = 'SpaceGroups'
const actionClearData = actionClearDataHandler(NAMESPACE)

const selectModule = state => state.modules.SpaceGroups
const selectAllSpaceGroups = createSelector(selectModule, module => module.data)

const fetchSpaceGroups = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaceGroups'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceGroupsInstance = new GetSpaceGroups(store, {})

    const response = await handleCreateAsyncThunkResult(
      getSpaceGroupsInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)
const spaceGroupsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceGroups.pending, pendingDataHandler)
    builder.addCase(fetchSpaceGroups.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceGroups.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: spaceGroupsSlice.reducer }

export { selectAllSpaceGroups, actionClearData, fetchSpaceGroups }
