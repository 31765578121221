import { createSelector } from 'reselect'

import { combineAndSortShelfItems } from 'src/utils/shelfItems'

import { buildReducer, bindSelector } from 'src/store/modules'

import { GetDeptBuyers, GetSpaceAssignments } from 'src/api/MasterPSA'
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import uiReducer from './reducer'

const NAMESPACE = 'MasterPsa'
const DEPTBUYERS = 'DeptBuyer'
const SPACEASSIGNMENTS = 'SpaceAssignments'
const DEFAULT_SIZE = 10
const DEFAULT_PAGE_NUMBER = 1

const selectFilters = bindSelector(NAMESPACE)(({ ui: { filters } }) => filters)

const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const selectModule = state => state.modules.MasterPsa
const selectDeptBuyers = createSelector(selectModule, module => module.DeptBuyer.data)
const selectSpaceAssignments = createSelector(
  selectModule,
  module => module.SpaceAssignments.data.data
)

const selectMasterPsaEntriesStatus = createSelector(
  selectModule,
  module => module.SpaceAssignments.isFetching
)

const selectPagination = createSelector(selectModule, module => {
  const pageDetail = module.SpaceAssignments.data
  const pagination = {
    totalPages: pageDetail && pageDetail?.totalPages,
    number: pageDetail ? pageDetail?.currentPage : DEFAULT_PAGE_NUMBER,
    size: pageDetail ? pageDetail?.pageSize : DEFAULT_SIZE,
  }
  return pagination
})

const selectMasterPsaEntries = createSelector(selectSpaceAssignments, spaceAssignments => {
  const masterPSAEntries = spaceAssignments.reduce((spaceDetails, spaceItem) => {
    const {
      spaceTitle,
      startDate,
      endDate,
      id: spaceAssignmentId,
      comment: spaceAssignmentComment,
      spaceInstance,
    } = spaceItem
    const {
      id: spaceInstanceId,
      clusters,
      shelves,
      comment: spaceInstanceComment,
      posComment: spaceInstancePosComment,
      shelfHeights: spaceInstanceShelfHeights,
      suggestedLocation: spaceInstanceSuggestedLocation,
      layoutType: spaceInstanceLayoutType,
    } = spaceInstance
    const sortShelvesItem = [...shelves].sort((a, b) => a.position - b.position)
    const rows = sortShelvesItem.reduce((output, shelf, shelfIndex) => {
      const sortSelfArtifacts = [...shelf.artifacts].sort((a, b) => a.position - b.position)
      const sortSelfProducts = [...shelf.products].sort((a, b) => a.position - b.position)
      const shelfItems = combineAndSortShelfItems(sortSelfProducts, sortSelfArtifacts)
      const shelfItemRows = shelfItems.map((shelfItem, shelfItemIndex) => {
        if (shelfItem.type === 'shelves-products') {
          return {
            ...shelfItem,
            sku: shelfItem?.sku,
            deptComm: shelfItem?.deptComm,
            description: shelfItem?.description,
            clusters: shelfItem?.clusters,
            mechanic: shelfItem?.mechanicType,
            mechanicText: shelfItem?.promoMechanicValue,
            shelfFill: shelfItem?.shelfFill,
            shelfFillType: shelfItem?.shelfFillType,
            shelfIndex,
            shelfItemIndex,
            shelf,
            startDate,
            endDate,
            isFirstOnShelf: shelfItemIndex === 0,
            isLastOnShelf: shelfItemIndex === shelfItems.length - 1,
            shelfProductId: shelfItem?.id,
            shelvesLength: shelves.length,
            isShelfRsb: shelf?.isRsb,
            shelfProductLayouts: shelfItem?.layouts,
          }
        }
        return {
          ...shelfItem,
          shelfIndex,
          shelfItemIndex,
          shelf,
          startDate,
          endDate,
          isShelfRsb: shelf.isRsb,
          shelvesLength: shelves.length,
        }
      })
      return output.concat(shelfItemRows)
    }, [])

    spaceDetails.push({
      spaceInstanceId,
      clusters: clusters !== undefined ? clusters : '',
      spaceTitle,
      spaceAssignmentComment,
      spaceAssignmentId,
      spaceInstanceComment,
      spaceInstancePosComment,
      spaceInstanceShelfHeights,
      spaceInstanceSuggestedLocation,
      spaceInstanceLayoutType,
      rows,
    })
    return spaceDetails
  }, [])

  const sortedMasterPSAEntries = masterPSAEntries.sort((assignmentA, assignmentB) =>
    assignmentA.spaceTitle.localeCompare(assignmentB.spaceTitle, undefined, {
      numeric: true,
    })
  )

  return sortedMasterPSAEntries
})

const fetchDeptBuyers = createAsyncThunk(
  buildThunkPrefix(DEPTBUYERS, 'fetchDeptBuyers'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getDeptBuyersInstance = new GetDeptBuyers(store)

    const response = await handleCreateAsyncThunkResult(
      getDeptBuyersInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const deptBuyersSlice = createSlice({
  name: DEPTBUYERS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDeptBuyers.pending, pendingDataHandler)
    builder.addCase(fetchDeptBuyers.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchDeptBuyers.rejected, rejectedDataHandler)
  },
})

const fetchSpaceAssignments = createAsyncThunk(
  buildThunkPrefix(SPACEASSIGNMENTS, 'fetchSpaceAssignments'),
  async ({ id, categoryIds, buyers, space, page }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceAssignmentsInstance = new GetSpaceAssignments(store, {
      params: { id, categoryIds, buyers, space, page },
    })

    const response = await handleCreateAsyncThunkResult(
      getSpaceAssignmentsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spaceAssignmentsSlice = createSlice({
  name: SPACEASSIGNMENTS,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchSpaceAssignments.pending, pendingDataHandler)
    builder.addCase(fetchSpaceAssignments.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceAssignments.rejected, rejectedDataHandler)
  },
})

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [deptBuyersSlice.name]: deptBuyersSlice.reducer,
  [spaceAssignmentsSlice.name]: spaceAssignmentsSlice.reducer,
})
export {
  actionClearData,
  selectFilters,
  selectPagination,
  selectDeptBuyers,
  fetchDeptBuyers,
  fetchSpaceAssignments,
  selectMasterPsaEntries,
  selectMasterPsaEntriesStatus,
}
