import React from 'react'
import { connect } from 'react-redux'

import sortArray from 'src/utils/sortArray'

import { selectSpaceDetails } from 'src/store/modules/asyncThunks/spaceDetails'
import { selectAllSpaceGroups } from 'src/modules/SpaceGroups/store'
import { selectAllSpaceTypes } from 'src/modules/SpaceTypes/store'
import { hasRequiredRole } from 'src/utils/permissions'
import { selectSelf } from 'src/store/data/selectors'
import { COMMERCIAL_OPS_SUPER_USER_ID } from 'src/constants/roles'
import { useNavigate } from 'react-router-dom'
import { actionUpdateSpaceById } from '../store'
import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const space = selectSpaceDetails(state) || {}
  const unsortedSpaceTypeOptions = selectAllSpaceTypes(state).map(type => ({
    id: type.id,
    label: type.title,
    value: type.id,
  }))
  const spaceTypeOptions = sortArray(unsortedSpaceTypeOptions, 'label')

  const unsortedSpaceGroupOptions = selectAllSpaceGroups(state).map(type => ({
    id: type.id,
    label: type.title,
    value: type.id,
  }))

  const spaceGroupOptions = sortArray(unsortedSpaceGroupOptions, 'label')

  let disableHfssButton = true

  const user = selectSelf(state)

  if (hasRequiredRole(user, COMMERCIAL_OPS_SUPER_USER_ID)) {
    disableHfssButton = false
  }

  return {
    id,
    ...space,
    spaceType: space.spaceType && space.spaceType.id,
    spaceGroup: space.spaceGroup && space.spaceGroup.id,
    spaceTypeOptions,
    spaceGroupOptions,
    disableHfssButton,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/spaces')
  }

  const mapDispatchToProps = dispatch => ({
    afterSubmit: () => returnAction(),
    cancel: () => returnAction(),
    submit: async (id, space) => {
      await dispatch(actionUpdateSpaceById({ id, space }))
    },
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
