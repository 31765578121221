import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import React from 'react'
import { Route } from 'react-router-dom'
import ChangeControlsDetail from 'src/modules/ChangeControlsDetail'

const ChangeControlsDetailRoute = (
  <Route
    path="/change-controls/:id"
    element={<AuthorizedComponent component={ChangeControlsDetail} />}
  />
)

export default ChangeControlsDetailRoute
