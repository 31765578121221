import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Heading3 } from '@jsluna/react'

import Section from 'src/components/Section'
import TimelineTable from 'src/components/TimelineTable'

import { selectNominationDetails } from './store'
import Form from './Form'

export function Component({ nomination, user }) {
  return (
    <div>
      <Form {...{ nomination, user }} />
      <Section>
        <Heading3>Timeline</Heading3>
        <Card>
          <TimelineTable entity={nomination} timeline={nomination.timeline} />
        </Card>
      </Section>
    </div>
  )
}

Component.propTypes = {
  nomination: PropTypes.shape({ timeline: PropTypes.shape({}) }),
  user: PropTypes.shape({}),
}

Component.defaultProps = {
  nomination: null,
  user: null,
}

const mapStateToProps = state => ({
  nomination: selectNominationDetails(state),
})

export default connect(mapStateToProps)(Component)
