import React from 'react'
import PropTypes from 'prop-types'
import Row from './Row'

function Body({ rows }) {
  return (
    <tbody className="ln-c-table__body">
      {rows.length ? (
        rows.map(row => <Row key={row.id} cells={row.cells} />)
      ) : (
        <tr className="ln-c-table__row">
          <td className="ln-c-table__cell">No results found</td>
        </tr>
      )}
    </tbody>
  )
}

Body.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    })
  ).isRequired,
}

export default Body
