import React from 'react'
import PropTypes from 'prop-types'

export const Component = () => {
  return <span>N/A</span>
}

Component.propTypes = {
  approval: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
}

export default Component
