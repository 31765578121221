import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { FilledButton, GridWrapper, GridItem, List, StatusCard } from '@jsluna/react'

import {
  CHANGE_CONTROL_STATUS_REJECTED,
  CHANGE_CONTROL_STATUS_WITHDRAWN,
} from 'src/constants/changeControlStatus'

const LabelledText = ({ label, value }) => (
  <div>
    <div className="ln-c-label ln-u-soft-right">{label}</div>
    <div data-control="text-value">{value}</div>
  </div>
)

LabelledText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

const Summary = ({ id, messages, reason, spaceTitle, status, statusCardStatus }) => (
  <StatusCard
    className="ln-u-push-bottom-lg u-negative-right"
    status={statusCardStatus}
    data-control="change-control"
  >
    <GridWrapper className="ln-u-push-bottom">
      <GridItem size="2/12" data-control="change-control-space">
        <LabelledText label="Space" value={spaceTitle} />
      </GridItem>

      <GridItem size="2/12" data-control="change-control-status">
        <LabelledText label="Status" value={status} />
      </GridItem>

      <GridItem size="2/12" data-control="change-control-reason-code">
        <LabelledText label="Reason Code" value={reason.changeControlReasonCode} />
      </GridItem>

      <GridItem size="5/12" data-control="change-control-reason-desc">
        <LabelledText label="Reason Description" value={reason.changeControlReasonDescription} />
      </GridItem>

      {![CHANGE_CONTROL_STATUS_REJECTED, CHANGE_CONTROL_STATUS_WITHDRAWN].includes(
        status.toLowerCase()
      ) && (
        <GridItem className="ln-u-push-bottom" size="1/12" data-control="change-control-detail">
          <FilledButton fullWidth element={<Link to={`/change-controls/${id}`} />}>
            View
          </FilledButton>
        </GridItem>
      )}
    </GridWrapper>

    <List
      className="ln-u-push-bottom"
      type="bare"
      items={messages}
      data-control="change-control-messages"
    />
  </StatusCard>
)

Summary.propTypes = {
  id: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
  reason: PropTypes.shape({
    changeControlReasonCode: PropTypes.string.isRequired,
    changeControlReasonDescription: PropTypes.string.isRequired,
  }).isRequired,
  spaceTitle: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusCardStatus: PropTypes.oneOf(['danger', 'success', 'warning', 'info']),
}

Summary.defaultProps = {
  messages: [],
  statusCardStatus: undefined,
}

export default Summary
