import React from 'react'
import PropTypes from 'prop-types'
import ChangeControls from 'src/modules/ChangeControls'

const Component = ({ params: { id } }) => <ChangeControls cycleId={id} />

Component.propTypes = {
  params: PropTypes.shape({ id: PropTypes.string }).isRequired,
}

export default Component
