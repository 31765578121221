import { ACCESSING_TOKEN_STATUS, SET_TOKEN, SUCCESS } from 'src/constants/api'

const initialState = null

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        ...payload,
        status: SUCCESS,
      }
    case ACCESSING_TOKEN_STATUS:
      return {
        ...state,
        status: payload,
      }
    default:
      return state
  }
}

export default reducer
