import React from 'react'
import PropTypes from 'prop-types'

import { Card, Heading3 } from '@jsluna/react'

import SpacePlanner from 'src/modules/SpacePlanner'
import Section from 'src/components/Section'
import { useSelector } from 'react-redux'
import { selectSpacePlansInfo } from '../store'

const Planner = ({ params: { id: spacePlanId }, location }) => {
  const spacePlan = useSelector(state => selectSpacePlansInfo(state, spacePlanId)) || {}
  const { id, isCyclesPresent } = spacePlan
  return isCyclesPresent ? (
    <Section>
      <Heading3 className="ln-u-visually-hidden">Planner</Heading3>
      <SpacePlanner returnTo={location.pathname} id={id} />
    </Section>
  ) : (
    <Card>This space plan has no cycles</Card>
  )
}

Planner.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Planner
