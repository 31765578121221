import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { selectSelf } from 'src/store/data/selectors'
import Failure from 'src/modules/Failure'
import { isAllowedRoute } from 'src/utils/routing'
import reconstructRoutePattern from 'src/utils/reconstructRoutePattern'

const AuthorizedComponent = ({ component: Component, ...props }) => {
  const user = useSelector(selectSelf)

  const params = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const currentPath = location.pathname
  const routePattern = reconstructRoutePattern(currentPath, params)

  const isAssignedRole = !!user && user.role
  const isAuthorized = isAllowedRoute(user, routePattern)
  if (!user || !isAssignedRole || !isAuthorized) {
    return <Failure />
  }

  return (
    <Component
      authData={{ user }}
      params={params}
      location={location}
      query={queryParams}
      {...props}
    />
  )
}

AuthorizedComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default AuthorizedComponent
