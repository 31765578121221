import { useEffect } from 'react'
import PropTypes from 'prop-types'

const InitialRoute = ({ setIsStoreColleague, isStoreColleague }) => {
  useEffect(() => {
    setIsStoreColleague(isStoreColleague)
  }, [setIsStoreColleague, isStoreColleague])

  return null
}
InitialRoute.propTypes = {
  setIsStoreColleague: PropTypes.func.isRequired,
  isStoreColleague: PropTypes.bool.isRequired,
}

export default InitialRoute
