import React from 'react'
import PropTypes from 'prop-types'
import { Route, Link, Outlet } from 'react-router-dom'
import { Heading2, Tabs, TabLink } from '@jsluna/react'

import SpacesList from 'src/modules/Spaces'
import SpacesDetail from 'src/modules/SpacesDetail'
import SpaceTypesList from 'src/modules/SpaceTypes'
import SpaceTypesDetail from 'src/modules/SpaceTypesDetail'
import SpaceGroupsList from 'src/modules/SpaceGroups'
import SpaceGroupsDetail from 'src/modules/SpaceGroupsDetail'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'

const isTabActive = (tabPath, pathName) => pathName?.endsWith(tabPath)

const tabs = [
  {
    to: '/spaces',
    label: 'Spaces',
  },
  {
    to: '/spaces/types',
    label: 'Types',
  },
  {
    to: '/spaces/groups',
    label: 'Groups',
  },
]

const SpacesTabs = ({ location }) => (
  <div>
    <Heading2>Spaces</Heading2>
    <Tabs>
      {tabs.map(tab => (
        <TabLink
          key={tab.to}
          element={<Link to={tab.to} />}
          active={isTabActive(tab.to, location?.pathname)}
        >
          {tab.label}
        </TabLink>
      ))}
    </Tabs>
    <Outlet />
  </div>
)

SpacesTabs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

const route = (
  <>
    <Route path="/spaces" element={<AuthorizedComponent component={SpacesTabs} />}>
      <Route index element={<SpacesList />} />
      <Route path="types" element={<SpaceTypesList />} />
      <Route path="groups" element={<SpaceGroupsList />} />
    </Route>
    <Route path="/spaces/:id" element={<AuthorizedComponent component={SpacesDetail} />} />
    <Route
      path="/spaces/types/create"
      element={<AuthorizedComponent component={SpaceTypesDetail} />}
    />
    <Route
      path="/spaces/types/:id"
      element={<AuthorizedComponent component={SpaceTypesDetail} />}
    />
    <Route
      path="/spaces/groups/create"
      element={<AuthorizedComponent component={SpaceGroupsDetail} />}
    />
    <Route
      path="/spaces/groups/:id"
      element={<AuthorizedComponent component={SpaceGroupsDetail} />}
    />
  </>
)

export default route
