import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import PropTypes from 'prop-types'
import createRoutes from './routes'

export const history = createBrowserHistory()

const CustomRouter = ({ children }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      setState({ location, action })
    })
    return () => {
      unlisten()
    }
  }, [])
  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  )
}

CustomRouter.propTypes = {
  children: PropTypes.node.isRequired,
}

const createRouter = store => <CustomRouter>{createRoutes(store)}</CustomRouter>

export default createRouter
