import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { FlagWrapper, FlagBody, FlagComponent, Heading5 } from '@jsluna/react'

import { DRAFT_ID, IN_REVIEW_ID, APPROVED_ID } from 'src/modules/Nominations/statuses'
import StatusCardChevron from 'src/components/StatusCardChevron'

import Status from './Status'

const classes = {
  [DRAFT_ID]: 'ln-c-status-card--grey',
  [IN_REVIEW_ID]: 'ln-c-status-card--amber',
  [APPROVED_ID]: 'ln-c-status-card--green',
}

const StatusCard = ({ nomination }) => {
  const { status, nominationId, categoryTitle } = nomination
  return (
    <Link
      to={`/nominations/${nominationId}`}
      className={classNames('ln-c-status-card ln-c-status-card--link', classes[status])}
    >
      <FlagWrapper className="ln-o-flag@sm">
        <FlagBody className="ln-c-status-card__content">
          <Heading5 className="ln-u-flush-bottom">{categoryTitle}</Heading5>
        </FlagBody>
        <FlagComponent nowrap className="ln-c-status-card__content c-nomination-status">
          <Status nomination={nomination} />
        </FlagComponent>
      </FlagWrapper>
      <StatusCardChevron />
    </Link>
  )
}

StatusCard.propTypes = {
  nomination: PropTypes.shape({
    nominationId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    categoryTitle: PropTypes.string.isRequired,
  }).isRequired,
}

export default StatusCard
