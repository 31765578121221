import React from 'react'
import { Link } from 'react-router-dom'

import { Card, Heading3 } from '@jsluna/react'

import { CREATE_SPACE_GROUPS } from 'src/constants/permissions'
import Section from 'src/components/Section'
import Table from 'src/components/Table'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import { selectAllSpaceGroups } from './store'
import columns from './columns'

const Spaces = () => (
  <div data-control="space-groups-list">
    <Section>
      <Card>
        <Heading3 className="ln-u-visually-hidden">Spaces Groups List</Heading3>
        <div className="ln-u-push-bottom">
          <WithRequiredPermissions permission={CREATE_SPACE_GROUPS}>
            <Link className="ln-c-button ln-c-button--outlined" to="/spaces/groups/create">
              Create
            </Link>
          </WithRequiredPermissions>
        </div>
        <Table
          type="spaceGroups"
          searchEnabled
          columns={columns}
          dataSelector={selectAllSpaceGroups}
        />
      </Card>
    </Section>
  </div>
)

export default Spaces
