import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import SpacePlansList from 'src/modules/SpacePlans'
import SpacePlansDetail from 'src/modules/SpacePlansDetail'
import DetailsTab from 'src/modules/SpacePlansDetail/Details'
import PlannerTab from 'src/modules/SpacePlansDetail/Planner'
import ApprovalsTab from 'src/modules/SpacePlansDetail/Approvals'
import CyclesTab from 'src/modules/SpacePlansDetail/Cycles'
import TimelineTab from 'src/modules/SpacePlansDetail/Timeline'

const routes = (
  <>
    <Route path="/space-plans" element={<AuthorizedComponent component={SpacePlansList} />} />
    <Route
      path="/space-plans/create"
      element={<AuthorizedComponent component={SpacePlansDetail} />}
    >
      <Route index element={<AuthorizedComponent component={DetailsTab} />} />
    </Route>
    <Route path="/space-plans/:id" element={<AuthorizedComponent component={SpacePlansDetail} />}>
      <Route index element={<AuthorizedComponent component={DetailsTab} />} />
      <Route path="planner" element={<AuthorizedComponent component={PlannerTab} />} />
      <Route path="approvals" element={<AuthorizedComponent component={ApprovalsTab} />} />
      <Route path="cycles" element={<AuthorizedComponent component={CyclesTab} />} />
      <Route path="timeline" element={<AuthorizedComponent component={TimelineTab} />} />
    </Route>
  </>
)

export default routes
