import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { StatusCard } from '@jsluna/react'

const PendingChangeControlCard = ({ businessUnit, cycleId }) => (
  <StatusCard className="ln-u-push-bottom" status="danger">
    <div className="ln-u-push-bottom">
      There is an existing change control request on this item. For food queries please contact{' '}
      <a
        className="ln-c-button ln-c-button--link"
        href="mailto:promotional.cycles@sainsburys.co.uk"
      >
        promotional.cycles@sainsburys.co.uk
      </a>{' '}
      and for non-food please contact{' '}
      <a className="ln-c-button ln-c-button--link" href="mailto:nf.promocycles@sainsburys.co.uk">
        nf.promocycles@sainsburys.co.uk
      </a>
      .
    </div>
    <Link
      className="ln-c-button ln-c-button--link"
      to={`/cycles/${businessUnit}/${cycleId}/change-controls`}
    >
      Go to change controls
    </Link>
  </StatusCard>
)

PendingChangeControlCard.propTypes = {
  businessUnit: PropTypes.string.isRequired,
  cycleId: PropTypes.string.isRequired,
}

export default PendingChangeControlCard
