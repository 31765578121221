import React from 'react'
import PropTypes from 'prop-types'

import MasterPsa from 'src/modules/MasterPsa'

const Component = ({ params: { id: cycleId }, location }) => (
  <MasterPsa cycleId={cycleId} location={location} />
)

Component.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
}

export default Component
