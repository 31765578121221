import React from 'react'
import PropTypes from 'prop-types'
import { GridItem } from '@jsluna/react'
import { Link } from 'react-router-dom'

const Previous = ({ previous }) => (
  <GridItem size="1/1">
    <div className="ln-c-label ln-u-soft-right">Previous</div>
    {previous && previous.id ? (
      <Link className="ln-c-standalone-link" to={`/space-plans/${previous.id}`}>
        {previous.title}
      </Link>
    ) : (
      <span>There is no previous space plan.</span>
    )}
  </GridItem>
)

Previous.propTypes = {
  previous: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
}

Previous.defaultProps = {
  previous: null,
}

export default Previous
