import React from 'react'
import { Container, SiteLayout } from '@jsluna/react'

import Header from 'src/modules/App/Header/StoreColleague'
import Activities from 'src/modules/App/Activities'

import { Outlet } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'

const App = () => (
  <SiteLayout>
    <Header />
    <main>
      <div className="ln-u-soft-top ln-c-header-body">
        <Container soft size="lg">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Container>
      </div>
    </main>
    <Activities />
  </SiteLayout>
)

App.defaultProps = {
  children: null,
}

export default App
