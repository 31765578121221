import { createSelector } from 'reselect'
import { buildReducer } from 'src/store/modules'
import { isNumber } from 'src/utils/validation'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetCycleStorePSAPdf, GetCycleStorePSAEntries } from 'src/api/StorePSA'

const NAMESPACE = 'StorePsa'
const DEFAULT_SIZE = 1

const actionClearData = actionClearDataHandler(NAMESPACE)

const selectModule = state => state.modules.StorePsa
const selectStorePsaGroups = createSelector(selectModule, module => module.StorePsa.data.data || [])
const selectPagination = createSelector(selectModule, module => {
  const pageDetail = module.StorePsa.data
  const pagination = {
    totalPages: pageDetail ? pageDetail.totalPages : DEFAULT_SIZE,
    number: pageDetail ? pageDetail.currentPage : DEFAULT_SIZE,
    size: pageDetail ? pageDetail.pageSize : DEFAULT_SIZE,
  }
  return pagination
})
const selectStorePsaStartDate = createSelector(
  selectModule,
  module => module.StorePsa.selectedStorePsaStartDate || ''
)

const selectStorePsaEndDate = createSelector(
  selectModule,
  module => module.StorePsa.selectedStorePsaEndDate || ''
)

const fetchStorePsaEntries = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchStorePsaEntries'),
  async (
    { cycleId, storeId, page, storePsaStartDate, storePsaEndDate, businessUnit },
    { getState, dispatch, rejectWithValue }
  ) => {
    const store = getState()
    const getStorePsaEntriesInstance = new GetCycleStorePSAEntries(store, {
      params: { cycleId, storeId, page, storePsaStartDate, storePsaEndDate, businessUnit },
    })

    const response = await handleCreateAsyncThunkResult(
      getStorePsaEntriesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchExportsStoreSpecificPsa = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchExportsStoreSpecificPsa'),
  async (
    { cycleId, storeId, businessUnit, startDate, endDate },
    { getState, dispatch, rejectWithValue }
  ) => {
    const store = getState()
    const getExportsStoreSpecificPsaInstance = new GetCycleStorePSAPdf(store, {
      params: { cycleId, storeId, businessUnit, startDate, endDate },
    })

    const response = await handleCreateAsyncThunkResult(
      getExportsStoreSpecificPsaInstance,
      dispatch,
      rejectWithValue,
      false
    )
    return response
  }
)

const storePsaEntriesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    updateStorePsaStartDate: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedStorePsaStartDate = action.payload
    },
    updateStorePsaEndDate: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedStorePsaEndDate = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStorePsaEntries.pending, pendingDataHandler)
    builder.addCase(fetchStorePsaEntries.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchStorePsaEntries.rejected, rejectedDataHandler)
  },
})

const actionGetData = ({
  cycleId,
  storeId,
  number = 1,
  location,
  startDate,
  endDate,
  businessUnit,
}) => async (dispatch, getState) => {
  const state = getState()
  const storePsaStartDate = startDate !== undefined ? startDate : selectStorePsaStartDate(state)
  const storePsaEndDate = endDate !== undefined ? endDate : selectStorePsaEndDate(state)
  const isFull = location && location.query?.full !== undefined
  const pageNumber = location ? parseInt(location.hash.substring(1), 10) : number
  const { size } = selectPagination(state)
  const page = !isFull && {
    number: isNumber(pageNumber) ? pageNumber : 1,
    size,
  }

  await dispatch(
    fetchStorePsaEntries({
      cycleId,
      storeId,
      page,
      storePsaStartDate,
      storePsaEndDate,
      businessUnit,
    })
  )
}

export const { updateStorePsaStartDate, updateStorePsaEndDate } = storePsaEntriesSlice.actions
export default buildReducer(NAMESPACE, {
  [storePsaEntriesSlice.name]: storePsaEntriesSlice.reducer,
})
export {
  actionClearData,
  actionGetData,
  selectPagination,
  selectStorePsaGroups,
  fetchExportsStoreSpecificPsa,
  selectStorePsaStartDate,
  selectStorePsaEndDate,
}
