import loader from 'src/hoc/loader'

import { actionClearData } from './store'
import { actionGetData } from './store/actions'
import MasterPsa from './MasterPsa'

const getData = async (props, dispatch) => {
  const { cycleId, location } = props

  await dispatch(actionGetData({ cycleId, location, filter: 'initalApiCall' }))
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(MasterPsa)
