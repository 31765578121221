import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import classNames from 'classnames'
import { GridItem } from '@jsluna/react'

const Status = ({ statuses, isReadOnly, statusDisabled }) => {
  const disabledState = isReadOnly || statusDisabled
  return (
    <GridItem size="1/2">
      <div className="ln-c-label ln-u-soft-right">Status</div>
      <Field
        className={classNames('ln-c-select', { 'ln-c-select--hide-carat': disabledState })}
        name="status"
        component="select"
        disabled={disabledState}
      >
        {statuses.map(({ id, title }) => (
          <option key={id} value={id}>
            {title}
          </option>
        ))}
      </Field>
    </GridItem>
  )
}

Status.propTypes = {
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  isReadOnly: PropTypes.bool,
  statusDisabled: PropTypes.bool,
}

Status.defaultProps = {
  statuses: [],
  isReadOnly: false,
  statusDisabled: false,
}

export default Status
