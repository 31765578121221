import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetSharePointNominations,
  PostGenerateCycleCategoriesByCycleId,
} from 'src/api/SharePointNomination'
import { buildReducer } from 'src/store/modules'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  buildThunkPrefix,
} from 'src/utils/createAsyncThunkHandler'
import { createSelector } from 'reselect'
import GetSharePointNominationTemplate from 'src/api/SharePointNomination/GetSharePointNominationTemplate'

const NAMESPACE = 'Nominations'
const STORE_PATH = `modules/${NAMESPACE}`

const selectCyclesForNominations = state => state.modules.Nominations.SharepointNominations.data

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchNominations = createAsyncThunk(
  `${STORE_PATH}/SharepointNominations/fetchNominations`,
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getNominationsInstance = new GetSharePointNominations(store)

    const response = await handleCreateAsyncThunkResult(
      getNominationsInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const fetchNominationsSlice = createSlice({
  name: 'SharepointNominations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNominations.pending, pendingDataHandler)
    builder.addCase(fetchNominations.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchNominations.rejected, rejectedDataHandler)
  },
})

const selectNominationTemplateModule = state =>
  state.modules.Nominations.SharepointNominationTemplate
const selectNominationsTemplate = createSelector(
  selectNominationTemplateModule,
  module => module.data
)

const fetchNominationTemplate = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchNominationTemplate'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getNominationTemplateInstance = new GetSharePointNominationTemplate(store)

    const response = await handleCreateAsyncThunkResult(
      getNominationTemplateInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const fetchNominationTemplateSlice = createSlice({
  name: 'SharepointNominationTemplate',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNominationTemplate.pending, pendingDataHandler)
    builder.addCase(fetchNominationTemplate.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchNominationTemplate.rejected, rejectedDataHandler)
  },
})

const actionGenerateCycleCategoriesByCycleId = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionGenerateCycleCategoriesByCycleId'),
  async ({ cycleId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchSpaceInstance = new PostGenerateCycleCategoriesByCycleId(store, {
      params: { cycleId },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export {
  actionClearData,
  fetchNominations,
  fetchNominationTemplate,
  actionGenerateCycleCategoriesByCycleId,
}
export { selectCyclesForNominations, selectNominationsTemplate }

export default buildReducer(NAMESPACE, {
  [fetchNominationsSlice.name]: fetchNominationsSlice.reducer,
  [fetchNominationTemplateSlice.name]: fetchNominationTemplateSlice.reducer,
})
