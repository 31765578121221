import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'

import CyclesList from 'src/modules/Cycles'
import CyclesDetail from 'src/modules/CyclesDetail'
import DetailsTab from 'src/modules/CyclesDetail/Details'
import NominationsTab from 'src/modules/CyclesDetail/Nominations'
import MasterPsaTab from 'src/modules/CyclesDetail/MasterPsa'
import ChangeControlTab from 'src/modules/CyclesDetail/ChangeControls'
import StorePsaTab from 'src/modules/CyclesDetail/StorePsa'
import ShipperComplianceTab from 'src/modules/CyclesDetail/ShipperCompliance'
import SSPSACommentsTab from 'src/modules/CyclesDetail/SpaceComments'
import SpacesTab from 'src/modules/CyclesDetail/Spaces'

const routes = (
  <>
    <Route path="/cycles/:businessUnit" element={<AuthorizedComponent component={CyclesList} />} />
    <Route
      path="/cycles/:businessUnit/create"
      element={<AuthorizedComponent component={CyclesDetail} />}
    >
      <Route index element={<AuthorizedComponent component={DetailsTab} />} />
    </Route>
    <Route
      path="/cycles/:businessUnit/:id"
      element={<AuthorizedComponent component={CyclesDetail} />}
    >
      <Route index element={<AuthorizedComponent component={SpacesTab} />} />
      <Route path="details" element={<AuthorizedComponent component={DetailsTab} />} />
      <Route path="nominations" element={<AuthorizedComponent component={NominationsTab} />} />
      <Route path="master-psa" element={<AuthorizedComponent component={MasterPsaTab} />} />
      <Route
        path="change-controls"
        element={<AuthorizedComponent component={ChangeControlTab} />}
      />
      <Route path="store-psa" element={<AuthorizedComponent component={StorePsaTab} />} />
      <Route path="store-psa/:storeId" element={<AuthorizedComponent component={StorePsaTab} />} />
      <Route
        path="shipper-compliance"
        element={<AuthorizedComponent component={ShipperComplianceTab} />}
      />
      <Route path="sspsa-comments" element={<AuthorizedComponent component={SSPSACommentsTab} />} />
    </Route>
  </>
)

export default routes
