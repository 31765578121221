import React from 'react'
import { connect } from 'react-redux'

import { selectCategories } from 'src/store/modules/asyncThunks/categories'
import { selectAllRoles } from 'src/store/modules/asyncThunks/roles'
import { useNavigate } from 'react-router-dom'
import {
  selectUserInfo,
  updateUser,
  selectUserRequestedInfo,
  selectIsNewUserRequestedStatus,
} from '../store'

import Form from './Form'

export const mapStateToProps = state => {
  const user = selectUserInfo(state) || {}
  const userRequested = selectUserRequestedInfo(state) || {}
  const allRoles = selectAllRoles(state) || []
  const allCategories = selectCategories(state) || []
  const isUserRequestedStatus = selectIsNewUserRequestedStatus(state) || false

  return {
    user: !isUserRequestedStatus ? user : userRequested,
    roles: [...allRoles],
    allCategories: [...allCategories],
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/users')
  }

  const mapDispatchToProps = dispatch => ({
    submit: async (user, id) => {
      await dispatch(updateUser(id, user))
      returnAction()
    },
    handleReject: async user => {
      await dispatch(updateUser(user.id, user, true))
      returnAction()
    },
    cancel: () => returnAction(),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
