import React from 'react'

import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'

import { connect } from 'react-redux'
import {
  Form,
  GridWrapper,
  GridItem,
  Heading3,
  Section,
  Card,
  List,
  ListItem,
  FilledButton,
} from '@jsluna/react'
import { SelectField, TextAreaField } from '@jsluna/form/extensions/formik'

import { isDraft, isPending } from 'src/utils/changeControls'
import { hasRequiredPermission } from 'src/utils/permissions'

import { selectSelf } from 'src/store/data/selectors'
import { Controls } from 'src/components/Form'

import { DELETE_CHANGE_CONTROLS_ALL, DELETE_CHANGE_CONTROLS_OWN } from 'src/constants/permissions'

import pickSubmitChangeControl from 'src/store/api/actions/updateChangeControl'
import { selectChangeControlReasons } from 'src/modules/SpaceAssignment/store/reducer/changeControlReasons'
import { useNavigate } from 'react-router-dom'
import PendingChangeControlCard from '../PendingChangeControlCard'
import DeleteDialog from './DeleteDialog'
import SubmitDialog from './SubmitDialog'

import { selectUi } from '../store/index'
import { deleteChangeControl as deleteChangeControlAction } from '../store/actions'
import handleSubmit from './handleSubmit'
import validate from './validate'

const toOption = ({ title, id, code }) => ({ id, value: id, label: title || code })
const getChangeControlReasonDesc = (reasons = [], reasonCode = '') => {
  const { description = '' } = reasons.find(r => r.id === reasonCode) || {}

  return description
}

const isSameUser = (user1, user2) => user1.id === user2?.id

const ChangeControl = props => {
  const {
    changeControlReasons,
    values,
    handleSubmit: onSubmit,
    changeControl,
    isChangeControlCreator,
    isChangeControlView,
    isPendingChangeControl,
    canCancel,
    canSubmit,
    deleteChangeControl,
    submitChangeControl,
  } = props

  const changeControlCreator =
    changeControl && !isChangeControlCreator ? changeControl.createdBy.name : 'you'

  return (
    <Section data-control="shelf-assignment-change-control">
      <Heading3>Change Control</Heading3>
      <Card>
        {isPendingChangeControl && (
          <PendingChangeControlCard
            createdBy={changeControlCreator}
            data-control="pending-request-card"
            id={changeControl.id}
            isChangeControlView={isChangeControlView}
            spaceAssignmentId={changeControl.spaceAssignmentId}
          />
        )}
        <Form onSubmit={onSubmit}>
          <GridWrapper>
            <GridItem size="1/1">
              <Field
                label="Reason Code"
                name="changeControlReasonCode"
                component={SelectField}
                options={changeControlReasons.map(toOption)}
                validationFirst={false}
                disabled={!!changeControl}
              />
            </GridItem>

            <GridItem size="1/1">
              <Field
                className="ln-u-flush-bottom"
                label="Reason Description"
                name="changeControlDescription"
                component={TextAreaField}
                validationFirst={false}
                placeholder={getChangeControlReasonDesc(
                  changeControlReasons,
                  values.changeControlReasonCode
                )}
                disabled={!!changeControl}
              />
            </GridItem>

            {!isChangeControlView && !changeControl && (
              <GridItem size="1/2" className="ln-u-soft-top">
                <Controls submitText="Initiate changes" data-control="controls-initiate" />
              </GridItem>
            )}

            {isChangeControlView && (
              <GridItem size="1/2" className="ln-u-soft-top">
                <List type="matrix">
                  {canSubmit && (
                    <ListItem type="matrix">
                      <SubmitDialog
                        changeControlId={changeControl.id}
                        submitChangeControl={submitChangeControl}
                        element={({ onClick }) => (
                          <FilledButton onClick={onClick} data-control="controls-submit">
                            Submit
                          </FilledButton>
                        )}
                      />
                    </ListItem>
                  )}
                  {canCancel && (
                    <ListItem type="matrix">
                      <DeleteDialog
                        changeControlId={changeControl.id}
                        deleteChangeControl={deleteChangeControl}
                        element={({ onClick }) => (
                          <FilledButton
                            color="dark"
                            onClick={onClick}
                            data-control="controls-cancel"
                          >
                            Cancel
                          </FilledButton>
                        )}
                      />
                    </ListItem>
                  )}
                </List>
              </GridItem>
            )}
          </GridWrapper>
        </Form>
      </Card>
    </Section>
  )
}

ChangeControl.propTypes = {
  canCancel: PropTypes.bool.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  changeControlReasons: PropTypes.arrayOf(PropTypes.shape({})),
  currentUser: PropTypes.shape({}),
  deleteChangeControl: PropTypes.func,
  handleSubmit: PropTypes.func,
  changeControl: PropTypes.shape({
    createdBy: PropTypes.shape({ name: PropTypes.string }),
    space: PropTypes.shape({ businessUnit: PropTypes.string }),
    cycle: PropTypes.shape({ id: PropTypes.string }),
    id: PropTypes.string,
    spaceAssignmentId: PropTypes.string,
  }),
  isChangeControlCreator: PropTypes.bool.isRequired,
  isChangeControlView: PropTypes.bool.isRequired,
  isPendingChangeControl: PropTypes.bool.isRequired,
  submitChangeControl: PropTypes.func,
  values: PropTypes.shape({
    changeControlReasonCode: PropTypes.string,
  }),
}

ChangeControl.defaultProps = {
  changeControlReasons: [],
  currentUser: null,
  deleteChangeControl: () => {},
  submitChangeControl: () => {},
  handleSubmit: () => {},
  changeControl: null,
  values: {},
}

const wrappedForm = withFormik({ handleSubmit, validate })(ChangeControl)

const mapStateToProps = (state, { changeControl }) => {
  const { viewChangeControl } = selectUi(state)
  const currentUser = selectSelf(state)
  const isChangeControlView = !!viewChangeControl
  const isChangeControlCreator = changeControl
    ? isSameUser(currentUser, changeControl.createdBy)
    : false

  const canSubmit = isDraft(changeControl) && isChangeControlCreator
  const canCancel =
    isDraft(changeControl) &&
    (hasRequiredPermission(currentUser, DELETE_CHANGE_CONTROLS_ALL) ||
      (hasRequiredPermission(currentUser, DELETE_CHANGE_CONTROLS_OWN) && isChangeControlCreator))

  return {
    canCancel,
    canSubmit,
    changeControlReasons: selectChangeControlReasons(state),
    changeControlReasonCode: changeControl ? changeControl.changeControlReason.id : '',
    changeControlDescription: changeControl ? changeControl.description : '',
    isChangeControlView,
    isChangeControlCreator,
    isPendingChangeControl: isPending(changeControl),
    changeControl,
  }
}

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = (spaceAssignmentId, changeControlId) => {
    if (changeControlId) {
      return navigate(`/space-assignments/${spaceAssignmentId}/change-controls/${changeControlId}`)
    }

    return navigate(`/space-assignments/${spaceAssignmentId}`)
  }

  const mapDispatchToProps = (dispatch, { assignment }) => ({
    afterInit: (spaceAssignmentId, changeControl) => () => {
      const { successResult: id } = changeControl
      returnAction(spaceAssignmentId, id)
    },
    deleteChangeControl: async id => {
      await dispatch(deleteChangeControlAction(id))

      returnAction(assignment.id)
    },
    submitChangeControl: async id => {
      await dispatch(pickSubmitChangeControl(id, { status: 'submitted' }))

      returnAction(assignment.id)
    },
    submit: action => dispatch(action),
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(wrappedForm)

  return <ConnectedForm {...props} />
}

export default FormContainer
