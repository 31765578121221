import React from 'react'
import PropTypes from 'prop-types'

import { Card, Table, Heading3 } from '@jsluna/react'

import sortArray from 'src/utils/sortArray'

import Section from 'src/components/Section'
import { DRAFT_ID } from 'src/modules/SpacePlans/statuses'

import { connect } from 'react-redux'
import Status from './Status'
import Actions from './Actions'
import { selectApprovalBySpacePlan } from '../store'

const Approvals = ({ spacePlanApprovals, spacePlanStatus, user }) => {
  const columns = [
    {
      name: 'Title',
      accessor: 'title',
    },
    {
      name: 'Status',
      accessor: ({ approval }) => ({
        approval,
        spacePlanStatus,
      }),
      render: props => <Status {...props} />,
    },
    {
      name: 'Actions',
      accessor: ({ approval }) => ({
        approval,
        user,
        spacePlanStatus,
      }),
      render: props => <Actions {...props} />,
    },
  ]

  const data = sortArray([...spacePlanApprovals], 'title').map(approval => ({
    title: approval.title,
    approval,
  }))

  return (
    spacePlanStatus !== DRAFT_ID &&
    spacePlanApprovals.length > 0 && (
      <Section>
        <Heading3 className="ln-u-visually-hidden">Approvals</Heading3>
        <Card>
          <Table columns={columns} data={data} />
        </Card>
      </Section>
    )
  )
}

Approvals.propTypes = {
  spacePlanApprovals: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  spacePlanStatus: PropTypes.string.isRequired,
  user: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => {
  const getSpacePlanApprovals = selectApprovalBySpacePlan(state) || []
  const spacePlanApprovals = getSpacePlanApprovals?.spacePlanApprovals || []
  const spacePlanStatus = getSpacePlanApprovals?.status
  return { spacePlanApprovals, spacePlanStatus }
}
export default connect(mapStateToProps)(Approvals)
