import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import GetUserSelf from 'src/api/User/GetUserSelf'

const NAMESPACE = 'UserSelf'
const STORE_PATH = `modules/${NAMESPACE}`

const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchUserSelf = createAsyncThunk(
  buildThunkPrefix('fetchUserSelf'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchSelfInstance = new GetUserSelf(store)

    const response = await handleCreateAsyncThunkResult(
      fetchSelfInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const userSelfSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserSelf.pending, pendingDataHandler)
    builder.addCase(fetchUserSelf.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchUserSelf.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchUserSelf }

export default { [NAMESPACE]: userSelfSlice.reducer }
