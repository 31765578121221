import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  initialState,
  pendingDataHandler,
  rejectedDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetMostRecentSspsaList } from 'src/api/Sspsa'

const NAMESPACE = 'MostRecentSspsaList'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchMostRecentSspsaList = createAsyncThunk(
  buildThunkPrefix('fetchMostRecentSspsaList'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchMostRecentSspsaListInstance = new GetMostRecentSspsaList(store)

    const response = await handleCreateAsyncThunkResult(
      fetchMostRecentSspsaListInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const mostRecentSspsaListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMostRecentSspsaList.pending, pendingDataHandler)
    builder.addCase(fetchMostRecentSspsaList.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchMostRecentSspsaList.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchMostRecentSspsaList, mostRecentSspsaListSlice }

export default { [NAMESPACE]: mostRecentSspsaListSlice.reducer }
