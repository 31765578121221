import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  GetApprovalsBySpacePlan,
  GetCyclesBySpacePlan,
  GetLatestSpacePlan,
  GetSpacePlanById,
  GetTimelineBySpacePlan,
  PostSpacePlan,
  UpdateSpacePlanById,
  ApproveSpacePlanById,
} from 'src/api/SpacePlan'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'SpacePlansDetail'
const SPACEPLANS = 'SpacePlans'
const LATEST_SPACEPLAN = 'LatestSpacePlanDetail'
const CYCLE_DETAILS = 'CycleDetails'
const APPROVAL_DETAILS = 'ApprovalDetails'
const TIMELINE_DETAILS = 'TimelineDetail'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const selectSelf = state => state.modules.SpacePlansDetail
const selectSpacePlansInfo = createSelector(selectSelf, module => module.SpacePlans.data)
const selectPreviousSpacePlanInfo = createSelector(
  selectSelf,
  module => module[LATEST_SPACEPLAN].data
)
const selectCycleBySpacePlan = createSelector(selectSelf, module => module.CycleDetails.data)
const selectApprovalBySpacePlan = createSelector(selectSelf, module => module.ApprovalDetails.data)
const selectTimelineBySpacePlan = createSelector(selectSelf, module => module.TimelineDetail.data)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchSpacePlanById = createAsyncThunk(
  buildThunkPrefix(SPACEPLANS, 'fetchSpacePlanById'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpacePlanByIdInstance = new GetSpacePlanById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getSpacePlanByIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spacePlanByIdSlice = createSlice({
  name: SPACEPLANS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpacePlanById.pending, pendingDataHandler)
    builder.addCase(fetchSpacePlanById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpacePlanById.rejected, rejectedDataHandler)
  },
})

const fetchLatestSpacePlan = createAsyncThunk(
  buildThunkPrefix(LATEST_SPACEPLAN, 'fetchLatestSpacePlan'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getLatestSpacePlansInstance = new GetLatestSpacePlan(store)

    const response = await handleCreateAsyncThunkResult(
      getLatestSpacePlansInstance,
      dispatch,
      rejectWithValue
    )
    return response || {}
  }
)

const latestSpacePlanSlice = createSlice({
  name: LATEST_SPACEPLAN,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLatestSpacePlan.pending, pendingDataHandler)
    builder.addCase(fetchLatestSpacePlan.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchLatestSpacePlan.rejected, rejectedDataHandler)
  },
})

const updateSpacePlanById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateSpacePlanById'),
  async ({ id, spacePlan }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postSpacePlansInfoInstance = new UpdateSpacePlanById(store, {
      params: { id, spacePlan },
    })

    const response = await handleCreateAsyncThunkResult(
      postSpacePlansInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const createSpacePlan = createAsyncThunk(
  buildThunkPrefix('createSpacePlan'),
  async (spacePlan, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postSpacePlansInstance = new PostSpacePlan(store, {
      params: {
        spacePlan,
      },
    })

    const response = await handleCreateAsyncThunkResult(
      postSpacePlansInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchCyclesBySpacePlan = createAsyncThunk(
  buildThunkPrefix(CYCLE_DETAILS, 'fetchCyclesBySpacePlan'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getCycleBySpacePlanInstance = new GetCyclesBySpacePlan(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      getCycleBySpacePlanInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const cycleBySpacePlanSlice = createSlice({
  name: CYCLE_DETAILS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCyclesBySpacePlan.pending, pendingDataHandler)
    builder.addCase(fetchCyclesBySpacePlan.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCyclesBySpacePlan.rejected, rejectedDataHandler)
  },
})

const fetchApprovalsBySpacePlan = createAsyncThunk(
  buildThunkPrefix(APPROVAL_DETAILS, 'fetchApprovalsBySpacePlan'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getApprovalsBySpacePlanInstance = new GetApprovalsBySpacePlan(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      getApprovalsBySpacePlanInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const approvalBySpacePlanSlice = createSlice({
  name: APPROVAL_DETAILS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchApprovalsBySpacePlan.pending, pendingDataHandler)
    builder.addCase(fetchApprovalsBySpacePlan.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchApprovalsBySpacePlan.rejected, rejectedDataHandler)
  },
})

const fetchTimelineBySpacePlan = createAsyncThunk(
  buildThunkPrefix(TIMELINE_DETAILS, 'fetchTimelineBySpacePlan'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getTimelineBySpacePlanInstance = new GetTimelineBySpacePlan(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      getTimelineBySpacePlanInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const approveSpacePlanById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'spacePlanApprovalById'),
  async ({ id, spacePlan }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const spacePlanApprovalInstance = new ApproveSpacePlanById(store, {
      params: { id, spacePlan },
    })

    const response = await handleCreateAsyncThunkResult(
      spacePlanApprovalInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const timelineBySpacePlanSlice = createSlice({
  name: TIMELINE_DETAILS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTimelineBySpacePlan.pending, pendingDataHandler)
    builder.addCase(fetchTimelineBySpacePlan.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchTimelineBySpacePlan.rejected, rejectedDataHandler)
  },
})

export default {
  [NAMESPACE]: combineReducers({
    [spacePlanByIdSlice.name]: spacePlanByIdSlice.reducer,
    [latestSpacePlanSlice.name]: latestSpacePlanSlice.reducer,
    [cycleBySpacePlanSlice.name]: cycleBySpacePlanSlice.reducer,
    [approvalBySpacePlanSlice.name]: approvalBySpacePlanSlice.reducer,
    [timelineBySpacePlanSlice.name]: timelineBySpacePlanSlice.reducer,
  }),
}
export {
  actionClearData,
  fetchSpacePlanById,
  fetchLatestSpacePlan,
  updateSpacePlanById,
  createSpacePlan,
  selectSpacePlansInfo,
  selectPreviousSpacePlanInfo,
  fetchCyclesBySpacePlan,
  selectCycleBySpacePlan,
  fetchApprovalsBySpacePlan,
  selectApprovalBySpacePlan,
  fetchTimelineBySpacePlan,
  selectTimelineBySpacePlan,
  approveSpacePlanById,
}
