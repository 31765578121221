import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { GetCategories, UpdateCategoryById } from 'src/api/Category'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'Categories'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const selectSelf = state => state.modules.Categories
const selectCategories = createSelector(selectSelf, module => module.data)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchCategories = createAsyncThunk(
  buildThunkPrefix('fetchCategories'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchCategoryInstance = new GetCategories(store)

    const response = await handleCreateAsyncThunkResult(
      fetchCategoryInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateCategory = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateCategoryInfo'),
  async ({ id, category }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchCategoryInfoInstance = new UpdateCategoryById(store, {
      params: { id, category },
    })
    const response = await handleCreateAsyncThunkResult(
      patchCategoryInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const categoriesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.pending, pendingDataHandler)
    builder.addCase(fetchCategories.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCategories.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchCategories, actionUpdateCategory, selectCategories }

export default { [NAMESPACE]: categoriesSlice.reducer }
