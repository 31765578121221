import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Field, withFormik } from 'formik'
import { Card, Form, GridWrapper, GridItem, List, Heading3 } from '@jsluna/react'

import { TextAreaField } from '@jsluna/form/extensions/formik'

import {
  UPDATE_CHANGE_CONTROLS_ACCEPT,
  UPDATE_CHANGE_CONTROLS_ACCEPT_OWN_CATEGORY,
  UPDATE_CHANGE_CONTROLS_AMEND,
  UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY,
  UPDATE_CHANGE_CONTROLS_APPROVE,
  UPDATE_CHANGE_CONTROLS_APPROVE_OWN_CATEGORY,
  UPDATE_CHANGE_CONTROLS_REJECT,
  UPDATE_CHANGE_CONTROLS_REJECT_OWN_CATEGORY,
  UPDATE_CHANGE_CONTROLS_WITHDRAW,
} from 'src/constants/permissions'

import Section from 'src/components/Section'
import WithPermission from 'src/components/WithPermission'
import ChangeTypeBadge from 'src/components/ChangeTypeBadge'

import SpaceInstances from './SpaceInstances'
import handleSubmit from './handleSubmit'
import validate from './validate'

import DetailButton from './components/DetailButton'
import AcceptButton from './components/AcceptButton'

const ChangeControlDetails = ({
  changeControlCategories,
  changeControlOwnerId,
  changes,
  handleSubmit: onSubmit,
  has4WKOnlyPermission,
  id,
  isSubmitted,
  is4WeeksToLive,
  isApproved,
  isOwnChangeControl,
  spaceAssignment,
  setFieldValue,
  canAmendOrReject,
  submitForm,
}) => (
  <div>
    <SpaceInstances assignment={spaceAssignment} changeControlId={id} />

    <div data-control="change-control-summary">
      <Heading3>Change Control Summary</Heading3>
      <Section>
        <Card>
          <div className="ln-u-push-bottom">
            <Link
              className="ln-c-button ln-c-button--link"
              data-control="space-assignment-view"
              to={`/space-assignments/${spaceAssignment.id}`}
              target="_blank"
            >
              Click here
            </Link>{' '}
            to view this space assignment without changes or &nbsp;
            <Link
              className="ln-c-button ln-c-button--link"
              data-control="change-control-view"
              to={`/space-assignments/${spaceAssignment.id}/change-controls/${id}`}
            >
              click here
            </Link>{' '}
            to view the space assignment with the changes.
          </div>

          <GridWrapper className="ln-u-push-bottom">
            {Object.keys(changes).map(
              c =>
                changes[c] !== 0 && (
                  <GridItem key={`change-type-${c}`} size={{ xs: '1/2', sm: '2/12' }}>
                    <ChangeTypeBadge id={`${id}`} type={`${c}`} count={changes[c]} />
                  </GridItem>
                )
            )}
          </GridWrapper>

          <div>
            <Form onSubmit={onSubmit} data-control="form-comment">
              {!is4WeeksToLive && has4WKOnlyPermission ? (
                <>
                  <List type="matrix">
                    <DetailButton
                      buttonType="withdraw"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={UPDATE_CHANGE_CONTROLS_WITHDRAW}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isSubmitted}
                      disabled
                    />

                    <DetailButton
                      buttonType="amend"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_AMEND,
                        UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={canAmendOrReject}
                      isOwnChangeControl={isOwnChangeControl}
                      disabled
                    />
                    <DetailButton
                      buttonType="approve"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_APPROVE,
                        UPDATE_CHANGE_CONTROLS_APPROVE_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isSubmitted}
                      disabled
                    />
                    <AcceptButton
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      isProposition={spaceAssignment.isProposition}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_ACCEPT,
                        UPDATE_CHANGE_CONTROLS_ACCEPT_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isApproved}
                      submitForm={submitForm}
                      disabled
                    />
                    <DetailButton
                      buttonType="reject"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_REJECT,
                        UPDATE_CHANGE_CONTROLS_REJECT_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={canAmendOrReject}
                      disabled
                    />
                  </List>
                </>
              ) : (
                <>
                  <WithPermission
                    permission={[
                      UPDATE_CHANGE_CONTROLS_APPROVE,
                      UPDATE_CHANGE_CONTROLS_REJECT,
                      UPDATE_CHANGE_CONTROLS_AMEND,
                    ]}
                    entityCategories={changeControlCategories}
                    entityOwnerId={changeControlOwnerId}
                    shouldDisplay={canAmendOrReject}
                  >
                    <GridWrapper>
                      <GridItem size="1/1">
                        <Field
                          data-control="comment"
                          name="comment"
                          label="Accept/Approve/Reject comment"
                          component={TextAreaField}
                          validationFirst={false}
                          placeholder="Please add a comment"
                          className="ln-u-soft-right"
                          autoFocus
                        />
                      </GridItem>
                    </GridWrapper>
                  </WithPermission>
                  <List type="matrix">
                    <DetailButton
                      buttonType="withdraw"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={UPDATE_CHANGE_CONTROLS_WITHDRAW}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isSubmitted}
                    />
                    <DetailButton
                      buttonType="amend"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_AMEND,
                        UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={canAmendOrReject}
                      isOwnChangeControl={isOwnChangeControl}
                    />
                    <DetailButton
                      buttonType="approve"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_APPROVE,
                        UPDATE_CHANGE_CONTROLS_APPROVE_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isSubmitted}
                    />
                    <AcceptButton
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      isProposition={spaceAssignment.isProposition}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_ACCEPT,
                        UPDATE_CHANGE_CONTROLS_ACCEPT_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={isApproved}
                      submitForm={submitForm}
                    />
                    <DetailButton
                      buttonType="reject"
                      changeControlCategories={changeControlCategories}
                      changeControlOwnerId={changeControlOwnerId}
                      permission={[
                        UPDATE_CHANGE_CONTROLS_REJECT,
                        UPDATE_CHANGE_CONTROLS_REJECT_OWN_CATEGORY,
                      ]}
                      setFieldValue={setFieldValue}
                      shouldDisplay={canAmendOrReject}
                    />
                  </List>
                </>
              )}
            </Form>
          </div>
        </Card>
      </Section>
    </div>
  </div>
)

ChangeControlDetails.propTypes = {
  changeControlCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeControlOwnerId: PropTypes.string.isRequired,
  changes: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  has4WKOnlyPermission: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  is4WeeksToLive: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isOwnChangeControl: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  canAmendOrReject: PropTypes.bool.isRequired,
  spaceAssignment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isProposition: PropTypes.bool,
  }).isRequired,
}

const wrappedForm = withFormik({ validate, handleSubmit })(ChangeControlDetails)

export default wrappedForm
