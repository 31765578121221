import React from 'react'
import PropTypes from 'prop-types'

import TemplateLink from './TemplateLink'

export const Container = props => {
  const { label, template } = props
  const { url } = template || {}

  return <TemplateLink url={url} label={url ? label : 'No Template'} />
}

Container.propTypes = {
  label: PropTypes.string,
  template: PropTypes.shape({
    url: PropTypes.string,
  }),
}

Container.defaultProps = {
  label: 'Open Template',
  template: {
    url: null,
  },
}

export default Container
