import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  createAction,
  combineReducers,
} from '@reduxjs/toolkit'
import {
  GetCycleById,
  UpdateCycleById,
  PostCycle,
  GetLatestCycleByGroup,
  GetSspsaCommentsByCycleId,
} from 'src/api/Cycle'
import { GetGroupTitles } from 'src/api/Group'

import GetSharePointNominationByCycleId from '../../api/Cycle/GetSharePointNominationByCycleId'

const NAMESPACE = 'CyclesDetail'
const CYCLE = 'Cycle'
const LATEST_CYCLE = 'LatestCyclesDetail'
const GROUP_TITLE_LIST = 'GroupTitleList'
const SSPSACOMMENTSBYCYCLEID = 'SspsaCommentsByCycleId'
const SHAREPOINT_NOMINATIONS_BY_CYCLE_ID = 'SharePointNominationByCycleId'

const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const actionUpdateCycle = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateCycleInfo'),
  async ({ id, cycle }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchCycleInfoInstance = new UpdateCycleById(store, {
      params: { id, cycle },
    })
    const response = await handleCreateAsyncThunkResult(
      patchCycleInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionCreateCycle = createAsyncThunk(
  buildThunkPrefix('createCycle'),
  async (cycle, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postCycleInstance = new PostCycle(store, {
      params: {
        cycle,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      postCycleInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const selectSelf = state => state.modules.CyclesDetail
const selectCycleInfo = createSelector(selectSelf, module => module.Cycle.data)
const selectPreviousCycleInfo = createSelector(selectSelf, module => {
  return module[LATEST_CYCLE]
})
const selectGroupTitleList = createSelector(selectSelf, module => {
  return module?.GroupTitleList?.data
})

const fetchSspsaCommentsByCycleId = createAsyncThunk(
  buildThunkPrefix(SSPSACOMMENTSBYCYCLEID, 'fetchSspsaCommentsByCycleId'),
  async (cycleId, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetSspsaCommentsByCycleIdInstance = new GetSspsaCommentsByCycleId(store, {
      params: { cycleId },
    })
    const response = await handleCreateAsyncThunkResult(
      GetSspsaCommentsByCycleIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const sspsaCommentsByCycleIdSlice = createSlice({
  name: SSPSACOMMENTSBYCYCLEID,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSspsaCommentsByCycleId.pending, pendingDataHandler)
    builder.addCase(fetchSspsaCommentsByCycleId.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSspsaCommentsByCycleId.rejected, rejectedDataHandler)
  },
})

const fetchGroupTitles = createAsyncThunk(
  buildThunkPrefix(GROUP_TITLE_LIST, 'fetchGroupTitles'),
  async (_, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetGroupTitlesInstance = new GetGroupTitles(store)
    const response = await handleCreateAsyncThunkResult(
      GetGroupTitlesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const groupTitleSlice = createSlice({
  name: GROUP_TITLE_LIST,
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGroupTitles.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
      }
    })
  },
})

const fetchLatestCycleByGroup = createAsyncThunk(
  buildThunkPrefix(LATEST_CYCLE, 'fetchLatestCycleByGroup'),
  async ({ businessUnit, groupId }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetLatestCycleByGroupInstance = new GetLatestCycleByGroup(store, {
      params: { businessUnit, groupId },
    })
    const response = await handleCreateAsyncThunkResult(
      GetLatestCycleByGroupInstance,
      dispatch,
      rejectWithValue
    )
    return response || {}
  }
)

const latestCycleSlice = createSlice({
  name: LATEST_CYCLE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLatestCycleByGroup.pending, pendingDataHandler)
    builder.addCase(fetchLatestCycleByGroup.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchLatestCycleByGroup.rejected, rejectedDataHandler)
  },
})

const fetchCycleById = createAsyncThunk(
  buildThunkPrefix(CYCLE, 'fetchCycleById'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const getCycleByIdInstance = new GetCycleById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getCycleByIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const cycleByIdSlice = createSlice({
  name: CYCLE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCycleById.pending, pendingDataHandler)
    builder.addCase(fetchCycleById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCycleById.rejected, rejectedDataHandler)
  },
})

const selectNominationsModule = state => state.modules.CyclesDetail.SharePointNominationByCycleId

const selectNominations = createSelector(selectNominationsModule, module => module.data)

const fetchNominations = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchNominations'),
  async (cycleId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const getCyclesInstance = new GetSharePointNominationByCycleId(store, {
      params: { cycleId },
    })

    const response = await handleCreateAsyncThunkResult(
      getCyclesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const nominationsSlice = createSlice({
  name: SHAREPOINT_NOMINATIONS_BY_CYCLE_ID,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchNominations.pending, pendingDataHandler)
    builder.addCase(fetchNominations.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchNominations.rejected, rejectedDataHandler)
  },
})

export default {
  [NAMESPACE]: combineReducers({
    [cycleByIdSlice.name]: cycleByIdSlice.reducer,
    [latestCycleSlice.name]: latestCycleSlice.reducer,
    [groupTitleSlice.name]: groupTitleSlice.reducer,
    [sspsaCommentsByCycleIdSlice.name]: sspsaCommentsByCycleIdSlice.reducer,
    [nominationsSlice.name]: nominationsSlice.reducer,
  }),
}

export {
  actionCreateCycle,
  actionUpdateCycle,
  actionClearData,
  selectCycleInfo,
  fetchCycleById,
  fetchLatestCycleByGroup,
  selectPreviousCycleInfo,
  fetchGroupTitles,
  selectGroupTitleList,
  fetchSspsaCommentsByCycleId,
  fetchNominations,
  selectNominations,
}
