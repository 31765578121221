import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { GetChangeControlSummaryById } from 'src/api/ChangeControl'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'ChangeControlsDetail'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const selectSelf = state => state.modules.ChangeControlsDetail
const selectChangeControl = createSelector(selectSelf, module => module.data)
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchChangeControl = createAsyncThunk(
  buildThunkPrefix('fetchChangeControl'),
  async (changeControlId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchChangeControlData = new GetChangeControlSummaryById(store, {
      params: { id: changeControlId },
    })
    const response = await handleCreateAsyncThunkResult(
      fetchChangeControlData,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const groupsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchChangeControl.pending, pendingDataHandler)
    builder.addCase(fetchChangeControl.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchChangeControl.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchChangeControl }
export { selectChangeControl }

export default { [NAMESPACE]: groupsSlice.reducer }
