import { API_FAILURE } from 'src/constants/api'
import { history } from 'src/router'

const middleware = () => next => action => {
  const { type, error, payload: { status } = {} } = action || {}

  if (type === API_FAILURE && error && (String(status) === '403' || String(status) === '401')) {
    history.push('/unauthorised')
  }

  next(action)
}

export default middleware
