import { ALL_ROLES_BY_ID } from 'src/constants/roles'
import { selectSelfRoleId } from 'src/store/data/selectors'
import { history } from 'src/router'

function redirect(path) {
  return (dispatch, getState) => {
    if (path === '/') {
      const roleId = selectSelfRoleId(getState())
      const role = ALL_ROLES_BY_ID[roleId]

      if (role && role.landingRoute) {
        history.push(role.landingRoute)
        return
      }
    }

    history.push(path)
  }
}

export default redirect
