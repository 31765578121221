import { buildReducer, bindSelector } from 'src/store/modules'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetShipperCompliancesByCycleId } from 'src/api/ShipperCompliance'
import uiReducer from './reducer'

const NAMESPACE = 'ShipperCompliance'
const SHIPPERCOMPLIANCEDETAILS = 'ShipperComplianceDetails'

const selectUi = bindSelector(NAMESPACE)(state => state.ui)

const actionClearData = actionClearDataHandler(NAMESPACE)

const fetchShipperCompliances = createAsyncThunk(
  buildThunkPrefix(SHIPPERCOMPLIANCEDETAILS, 'fetchShipperCompliances'),
  async ({ cycleId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getShipperCompliancesInstance = new GetShipperCompliancesByCycleId(store, {
      params: { cycleId },
    })

    const response = await handleCreateAsyncThunkResult(
      getShipperCompliancesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const shipperCompliancesSlice = createSlice({
  name: SHIPPERCOMPLIANCEDETAILS,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchShipperCompliances.pending, pendingDataHandler)
    builder.addCase(fetchShipperCompliances.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchShipperCompliances.rejected, rejectedDataHandler)
  },
})

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [shipperCompliancesSlice.name]: shipperCompliancesSlice.reducer,
})

export { selectUi, actionClearData, fetchShipperCompliances }
