import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { selectToken } from 'src/store/data/selectors'
import Failure from 'src/modules/Failure'

const AuthorizedStoreColleagueComponent = ({ component: Component }) => {
  const token = useSelector(selectToken)
  const params = useParams()
  if (!token) {
    return <Failure />
  }

  return <Component params={params} />
}

AuthorizedStoreColleagueComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
}

export default AuthorizedStoreColleagueComponent
