import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetPendingSspsaTasks, PostSspsaTask, DeleteSspsaTask } from 'src/api/Sspsa'
import {
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { createSelector } from 'reselect'
import { combineReducers } from 'redux'
import { availablePsaPreviewSlice } from './availablePsaPreview'
import { mostRecentSspsaListSlice } from './mostRecentSspsaList'

const NAMESPACE = 'Sspsa'
const CREATESSPSA = 'CreateSspsa'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const selectModule = state => state.modules.Sspsa
const selectPendingSspsas = createSelector(selectModule, module => module?.Sspsa?.data || [])
const selectCreateSspsaStatus = createSelector(selectModule, module => {
  return module?.CreateSspsa?.data?.successResult || false
})
const selectAvailablePsaPreview = createSelector(
  selectModule,
  module => module?.AvailablePsaPreview?.data || []
)

const selectMostRecentSspsaList = createSelector(
  selectModule,
  module => module?.MostRecentSspsaList?.data || []
)

const fetchPendingSspsaTasks = createAsyncThunk(
  NAMESPACE,
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getPendingSspsaTaskInstance = new GetPendingSspsaTasks(store)
    const response = await handleCreateAsyncThunkResult(
      getPendingSspsaTaskInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const createSspsaTask = createAsyncThunk(
  buildThunkPrefix('createSspsaTask'),
  async (generateSspsaTask, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postSspsaTaskInstance = new PostSspsaTask(store, {
      params: {
        generateSspsaTask,
      },
    })

    const response = await handleCreateAsyncThunkResult(
      postSspsaTaskInstance,
      dispatch,
      rejectWithValue
    )
    dispatch(fetchPendingSspsaTasks())
    return response
  }
)

const actionDeleteSspsaTask = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteSspsaTask'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteSspsaTaskInstance = new DeleteSspsaTask(store, {
      params: id,
    })

    const response = await handleCreateAsyncThunkResult(
      deleteSspsaTaskInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const SspsaTaskSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPendingSspsaTasks.pending, pendingDataHandler)
    builder.addCase(fetchPendingSspsaTasks.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchPendingSspsaTasks.rejected, rejectedDataHandler)
  },
})

const createSspsaTaskSlice = createSlice({
  name: CREATESSPSA,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createSspsaTask.pending, pendingDataHandler)
    builder.addCase(createSspsaTask.fulfilled, fullFilledDataHandler)
    builder.addCase(createSspsaTask.rejected, rejectedDataHandler)
  },
})

export default {
  [NAMESPACE]: combineReducers({
    [SspsaTaskSlice.name]: SspsaTaskSlice.reducer,
    [createSspsaTaskSlice.name]: createSspsaTaskSlice.reducer,
    [availablePsaPreviewSlice.name]: availablePsaPreviewSlice.reducer,
    [mostRecentSspsaListSlice.name]: mostRecentSspsaListSlice.reducer,
  }),
}

export {
  selectPendingSspsas,
  createSspsaTask,
  actionDeleteSspsaTask,
  fetchPendingSspsaTasks,
  actionClearData,
  selectCreateSspsaStatus,
  selectAvailablePsaPreview,
  selectMostRecentSspsaList,
}
