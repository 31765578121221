import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'

import { fetchSpaceDetails } from 'src/store/modules/asyncThunks/spaceDetails'
import { fetchCategories } from 'src/store/modules/asyncThunks/categories'
import { actionClearData } from './store'
import { setViewChangeControl } from './store/actions'
import SpaceAssignmentsDetail from './SpaceAssignmentsDetail'
import { fetchSpacePlanAssignment } from './store/reducer/spacePlanAssignment'
import { fetchChangeControlReasons } from './store/reducer/changeControlReasons'

export const getData = async ({ id, query: { space }, changeControlId }, dispatch) => {
  await Promise.all([
    dispatch(fetchCategories()),
    dispatch(fetchChangeControlReasons()),
    id ? dispatch(fetchSpacePlanAssignment({ id, changeControlId })).unwrap() : Promise.resolve({}),
    space ? dispatch(fetchSpaceDetails(space)) : Promise.resolve({}),
  ])

  dispatch(setViewChangeControl(changeControlId || ''))
}

const options = {
  Custom404: (
    <NotFound
      title="Space Assignment Not Found"
      message="The space assignment you are looking for cannot be found."
    />
  ),
  extractProps: ({ params, authData, query, children }) => ({
    ...params,
    ...authData,
    query: {
      space: query.get('space'),
      rt: query.get('rt'),
      startDate: query.get('startDate'),
      endDate: query.get('endDate'),
    },
    children,
  }),
  shouldRefresh: (
    { id: previousId, changeControlId: PreviousChangeControlId },
    { id, changeControlId }
  ) => previousId !== id || PreviousChangeControlId !== changeControlId,
  onUnmount: dispatch => actionClearData(dispatch),
}

export default loader(getData, options)(SpaceAssignmentsDetail)
