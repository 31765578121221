import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import { selectToken, selectTokenAccessStatus } from 'src/store/data/selectors'
import { FAILURE } from 'src/constants/api'
import LoadingCard from '../LoadingCard'

const LoggedInStoreColleagueRoute = () => {
  const token = useSelector(selectToken)
  const tokenAccessStatus = useSelector(selectTokenAccessStatus)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (token || tokenAccessStatus === FAILURE) {
      setIsLoading(false)
    }
  }, [token, tokenAccessStatus])

  useEffect(() => {
    if (!isLoading && !token && tokenAccessStatus === FAILURE) {
      navigate('/login-store-colleague')
    }
  }, [isLoading, token, tokenAccessStatus])

  if (isLoading) {
    return <LoadingCard />
  }

  return <Outlet />
}

export default LoggedInStoreColleagueRoute
