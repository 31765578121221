import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import React from 'react'
import { Route } from 'react-router-dom'

import ProductHfssDetail from 'src/modules/ProductHfssDetail'

const route = (
  <Route path="/product" element={<AuthorizedComponent component={ProductHfssDetail} />} />
)

export default route
