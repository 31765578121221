import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import { GetUsers } from 'src/api/User'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'Users'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const selectSelf = state => state.modules.Users
const selectUsers = createSelector(selectSelf, module => module.data)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchUsers = createAsyncThunk(
  buildThunkPrefix('fetchUsers'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchUsersInstance = new GetUsers(store)

    const response = await handleCreateAsyncThunkResult(
      fetchUsersInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const usersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUsers.pending, pendingDataHandler)
    builder.addCase(fetchUsers.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchUsers.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchUsers }
export { selectUsers }

export default { [NAMESPACE]: usersSlice.reducer }
