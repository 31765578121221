import React from 'react'
import { connect } from 'react-redux'

import { selectSelf } from 'src/store/data/selectors'
import { selectActiveCategories } from 'src/store/modules/asyncThunks/categories'
import { selectAllRoles } from 'src/store/modules/asyncThunks/roles'
import { useNavigate } from 'react-router-dom'
import { createNewUser } from '../store'

import Form from './Form'

const mapStateToProps = state => ({
  activeCategories: selectActiveCategories(state),
  categories: [],
  roles: [...(selectAllRoles(state) || [])],
  user: selectSelf(state),
})

const FormContainer = props => {
  const navigate = useNavigate()

  const returnAction = () => {
    navigate('/unassigned/submitted')
  }

  const mapDispatchToProps = dispatch => ({
    submit: async user => {
      const action = createNewUser(user)
      const response = await dispatch(action)
      if (response?.payload.successResult) returnAction()
    },
  })

  const ConnectedForm = connect(mapStateToProps, mapDispatchToProps)(Form)

  return <ConnectedForm {...props} />
}

export default FormContainer
