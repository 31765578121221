import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetSpaces } from 'src/api/Space'
import { createSelector } from 'reselect'

const NAMESPACE = 'Spaces'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const selectModule = state => state.modules.Spaces
const selectAllSpaces = createSelector(selectModule, module => module.data)

const fetchSpaces = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaces'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpacesInstance = new GetSpaces(store, {})

    const response = await handleCreateAsyncThunkResult(
      getSpacesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spacesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaces.pending, pendingDataHandler)
    builder.addCase(fetchSpaces.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaces.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: spacesSlice.reducer }

export { selectAllSpaces, actionClearData, fetchSpaces }
