import loader from 'src/hoc/loader'

import {
  actionGetData,
  actionClearData,
  updateStorePsaStartDate,
  updateStorePsaEndDate,
} from './store'
import Form, { getCycleStartDate, getCycleEndDate } from './Form'
import { selectCycleInfo } from '../CyclesDetail/store'

const getData = async (props, dispatch, state) => {
  const { cycleId, storeId, location, businessUnit } = props
  const cycle = selectCycleInfo(state())
  const startDate = getCycleStartDate(cycle.startDate)
  const endDate = getCycleEndDate(cycle.endDate)

  await dispatch(
    actionGetData({ cycleId, storeId, location, startDate, endDate: cycle.endDate, businessUnit })
  )
  await dispatch(updateStorePsaStartDate(startDate))
  await dispatch(updateStorePsaEndDate(endDate))
}

const options = {
  shouldRefresh: (previousProps, props) => {
    const { cycleId, storeId } = props
    const nextCycleId = previousProps.cycleId
    const nextStoreId = previousProps.storeId
    return cycleId !== nextCycleId || storeId !== nextStoreId
  },
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Form)
