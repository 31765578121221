import { createSelector } from 'reselect'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetMatchingShelfProductsById } from 'src/api/Shelf'

const NAMESPACE = 'ShelvesModal'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const selectSelf = state => state.modules.ShelvesModal
const selectSimilarShelfProducts = createSelector(selectSelf, module => module.data)

const fetchMatchingShelfProductsById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchMatchingShelfProductsById'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getMatchingShelfProductsByIdInstance = new GetMatchingShelfProductsById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getMatchingShelfProductsByIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const matchingShelfProductsByIdSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMatchingShelfProductsById.pending, pendingDataHandler)
    builder.addCase(fetchMatchingShelfProductsById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchMatchingShelfProductsById.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: matchingShelfProductsByIdSlice.reducer }
export { fetchMatchingShelfProductsById, selectSimilarShelfProducts }
