const reconstructRoutePattern = (pathname, params) => {
  let modifiedPathname = pathname
  if (pathname.endsWith('/')) {
    modifiedPathname = pathname.slice(0, -1)
  }
  return Object.entries(params).reduce((pattern, [key, value]) => {
    return pattern.replace(value, `:${key}`)
  }, modifiedPathname)
}

export default reconstructRoutePattern
