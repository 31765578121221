import React from 'react'
import { Route } from 'react-router-dom'
import AuthorizedComponent from 'src/components/Auth/AuthorizedComponent'
import SpaceAssignment from 'src/modules/SpaceAssignment'
import POS from 'src/modules/SpaceAssignment/POS'

const route = (
  <>
    <Route
      path="/space-assignments/create"
      element={<AuthorizedComponent component={SpaceAssignment} />}
    />
    <Route
      path="/space-assignments/:id"
      element={<AuthorizedComponent component={SpaceAssignment} />}
    />
    <Route
      path="/space-assignments/:id/change-controls/:changeControlId"
      element={<AuthorizedComponent component={SpaceAssignment} />}
    />
    <Route
      path="/space-assignments/:id/shelf/:shelfId/pos"
      element={<AuthorizedComponent component={POS} />}
    />
  </>
)

export default route
