import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  initialState,
  pendingDataHandler,
  rejectedDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetAvailablePsa } from 'src/api/RangeAndSpace'

const NAMESPACE = 'AvailablePSA'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchAvailablePsa = createAsyncThunk(
  buildThunkPrefix('fetchAvailablePsa'),
  async (storeNumber, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchAvailablePsaInstance = new GetAvailablePsa(store, {
      params: { storeNumber },
    })

    const response = await handleCreateAsyncThunkResult(
      fetchAvailablePsaInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const availablePsaSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAvailablePsa.pending, pendingDataHandler)
    builder.addCase(fetchAvailablePsa.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchAvailablePsa.rejected, rejectedDataHandler)
  },
})

export { actionClearData, fetchAvailablePsa }

export default { [NAMESPACE]: availablePsaSlice.reducer }
