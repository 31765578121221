import loader from 'src/hoc/loader'

import { actionClearData } from './store'
import SpaceComments from './SpaceComments'
import { fetchSspsaCommentsByCycleId } from '../store'

const getData = async ({ params: { id } }, dispatch) => {
  await Promise.all([dispatch(fetchSspsaCommentsByCycleId(id))])
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(SpaceComments)
